'use strict';
class JustificationsServices {
  constructor($http, $log, configuration) {
    this._http = $http;
    this.logger = $log;
    this.configuration = configuration;
  }

  /**
   * get justifications url from configuration
   *
   * @returns {string} justifications url
   */
  justificationsUrl() {
    return this.configuration.referentielJustification?.service;
  }

  /**
   * get demande justification url from configuration
   *
   * @returns {string} demande justification url
   */
  demandeJustificationUrl() {
    return `${this.configuration.aides?.justification?.service}/demandes-financement-justification`;
  }

  /**
   * check in the list of available teleservices justification if there's one occurence
   *
   * @returns {boolean} has at least one teleservice justification
   */
  hasTeleservices() {
    const params = {
      params: {
        $filter: 'active eq true',
      },
    };

    return this._http
      .get(`${this.justificationsUrl()}/teleservices`, params)
      .then(({ data }) => _.get(data, 'meta.total', 0) > 0)
      .catch((rejection) => {
        this.logger.error(
          `An error occurred trying to recover the number of active teleservices from the referentiel-justification with message: ${JSON.stringify(
            rejection.data
          )}`
        );

        return false;
      });
  }

  /**
   * Check the number of justification available for the given tiers
   *
   * @returns {Promise} the response
   */
  countDemandeJustifications() {
    return this._http
      .post(this.demandeJustificationUrl(), {})
      .then(({ data }) => data.total)
      .catch((rejection) => {
        this.logger.error(
          `An error occurred trying to recover the demandes to justify or with an ongoing justification from request with message : ${rejection.data}`
        );

        return 0;
      });
  }

  /**
   * Create a justification
   *
   * @param {object} justification
   * @returns {Promise}
   */
  createJustification(justification) {
    return this._http
      .post(`${this.justificationsUrl()}/justifications`, justification)
      .then(({ data }) => data)
      .catch(({ data }) => {
        this.logger.error(data);
        throw new Error(data);
      });
  }

  /**
   * Used to delete a justification
   *
   * @param {string} reference
   * @returns {Promise}
   */
  deleteJustification(reference) {
    const id = `${this.justificationsUrl()}/justifications/${reference}`;
    return this._http
      .delete(id)
      .then(({ data }) => (data != null ? id : false))
      .catch((err) => {
        this.logger.error(`An error occurred trying to delete the justification of the demande : ${err}`);
        return false;
      });
  }
}
JustificationsServices.$inject = ['$http', '$log', 'configuration'];

angular.module('justifications.services').service('justificationsService', JustificationsServices);
