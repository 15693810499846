'use strict';
/**
 * @module portailDepotDemandeAide
 * @name depotSimpleController
 * @description
 *
 *   This controller manages a series of page for 'simple' model of procedure
 */

/*eslint max-params:0 */
angular.module('portailDepotDemandeAide.depot').controller('depotSimpleController', [
  '$scope',
  '$rootScope',
  '$window',
  '$state',
  '$log',
  '$q',
  'teleserviceConfiguration',
  'simpleConfiguration',
  'aidesService',
  'teleservicesService',
  'alertsService',
  'tiersService',
  'tiersThematiquesService',
  'aide',
  'demandeur',
  'mdm',
  'publicSettingsFinancement',
  'contributionsFactory',
  'contributionsService',
  'viewsService',
  '$stateParams',
  '$timeout',
  'declarationCompteSignataire',
  'attestationDeclarationHonneur',
  '$translate',
  '$modal',
  'StoreService',
  'depotSimpleService',
  'IFrameCommunicationManager',
  'bourseService',
  'tiersPhysiquesAlreadyLinked',
  'indicateursService',
  'resolveService',
  'isAccessedThroughSharing',
  'tiersUsedForDepot',
  'isDemandeurReadOnly',
  'socketService',
  'lockAidesService',
  'lockingSocket',
  'tiersFacts',
  'teleserviceOpeningData',
  'teleserviceWithExtension',
  'teleserviceConfigurationWithRevision',
  function (
    $scope,
    $rootScope,
    $window,
    $state,
    $log,
    $q,
    teleserviceConfiguration,
    simpleConfiguration,
    aidesService,
    teleservicesService,
    alertsService,
    tiersService,
    tiersThematiquesService,
    aide,
    demandeur,
    mdm,
    publicSettingsFinancement,
    contributionsFactory,
    contributionsService,
    viewsService,
    $stateParams,
    $timeout,
    declarationCompteSignataire,
    attestationDeclarationHonneur,
    $translate,
    $modal,
    StoreService,
    depotSimpleService,
    IFrameCommunicationManager,
    bourseService,
    tiersPhysiquesAlreadyLinked,
    indicateursService,
    resolveService,
    isAccessedThroughSharing,
    tiersUsedForDepot,
    isDemandeurReadOnly,
    socketService,
    lockAidesService,
    lockingSocket,
    tiersFacts,
    teleserviceOpeningData,
    teleserviceWithExtension,
    teleserviceConfigurationWithRevision
  ) {
    const ctrl = this;

    /**
     * Clean when component is destroyed
     *
     * @returns {void}
     */
    ctrl.onDestroy = () => {
      // unset the demandeur in the store when leaving depot screen
      StoreService.demandeur.set(null);
      StoreService.beneficiaire.set(null);

      // Remove listeners
      _.each(listeners, (listener) => {
        $window.removeEventListener('message', listener);
      });

      closeIframeCommunicationManagers();

      // Disconnect locking socket to release the demande
      if (ctrl.lockingSocket) {
        socketService.close(ctrl.lockingSocket);
      }
    };

    ctrl.lockingSocket = lockingSocket;

    $scope.isAccessedThroughSharing = isAccessedThroughSharing;
    // If tiersUsedForDepot doesn't exist (contribution mode) we don't overwrite the tiers value
    if (tiersUsedForDepot) $scope.tiers = tiersUsedForDepot;
    $scope.isDemandeurReadOnly = isDemandeurReadOnly;
    $scope.teleserviceOpeningData = teleserviceOpeningData;

    const listeners = [];
    const stateViewsArray = [];

    const DEMANDEUR_CREATION_STEPS = tiersService.getDemandeurCreationSteps();
    const BENEFICIAIRE_CREATION_STEPS = tiersService.getBeneficiaireCreationSteps();
    let skipUpdate = false;
    // Keep track of scopes created in the controller...
    const scopes = [];
    // ... and delete them when the scope is destroyed
    $scope.$on('$destroy', function () {
      _.forEach(scopes, function (sc) {
        sc.$destroy();
      });
    });

    /**
     * Go to new router state, and resolve all promises before transition starts
     *
     * @param {*} stateName name state
     * @param {*} params params
     */
    const goToState = (stateName, params) => {
      ctrl.onDestroy();
      $state.go(stateName, params);
    };

    /**
     * Hide and alert after 5sec
     *
     * @param {*} alerts The created alert to hide in 5sec
     */
    const hideAlertAfterTimeout = (alerts) => {
      // alerts is an array but always has only one item
      $timeout(function () {
        alerts.pop(); // Remove the created alert from the array
      }, 5000);
    };

    $scope.aide = aide;
    $scope.oldAide = _.cloneDeep(aide);
    $scope.contribution = contributionsFactory.getContribution();
    const iframeCommunicationManagers = [];

    if ($scope.contribution) {
      $scope.teleserviceConfiguration = teleserviceConfigurationWithRevision ?? teleserviceConfiguration;
    }
    Object.assign($scope.teleserviceConfiguration, teleserviceWithExtension);

    /**
     * Redirect to the dashboard with an error if the user goes back to
     * a demande and has selected a tiers different than the demandeur
     *
     * ! Exception in sharing mode
     */
    if (!isAccessedThroughSharing && !aidesService.canAccessDepotForDemande($scope.aide, $scope.tiers)) {
      StoreService.dashboardAlert.set(
        alertsService.getAlertWarning($translate.instant('connected.depot.errors.demandeOfAnotherTiers'))
      );

      goToState('app.connected.dashboard.accueil');
      return; // do not load the component
    }

    const beneficiaires = _.get($scope, 'aide.beneficiaires');
    if (!_.isEmpty(beneficiaires) && beneficiaires[0].expand) {
      const beneficiaire = beneficiaires[0].expand;
      StoreService.beneficiaire.set(beneficiaire);
    }

    // If the demandeur's personnalité juridique is PHYSIQUE and a equivalent tiers physique is already linked to our user,
    // the tiers already linked will be used as demandeur
    // For now, it concerns two tiers type : TIERS_PARTICULIER and TIERS_ENTREPRENEUR_INDIVIDUEL
    if (demandeur) {
      if (!isAccessedThroughSharing && tiersPhysiquesAlreadyLinked) {
        const demandeurTypeFamille = demandeur?.famille?.expand?.typeFamille;
        const foundDemandeurReplacement = tiersPhysiquesAlreadyLinked.find(
          (tiers) => tiers.famille.expand.typeFamille === demandeurTypeFamille
        );

        if (foundDemandeurReplacement) {
          // Current demandeur must be changed...
          demandeur = foundDemandeurReplacement;
          // ...before we can launch our replacment process
          replaceDemandeurIfTiersPhysique();
        }
      }
      StoreService.demandeur.set(demandeur);
      $scope.aide.demandeur = {
        href: demandeur.id,
        title: demandeur.title,
        expand: demandeur,
      };
    }

    if (!isAccessedThroughSharing) {
      restartDepotProcessIfUnlinkedFromTiers();
    }

    /**
     * Unset the demandeur from the demande and go back to preambule if the user
     * is not linked to the tiers anymore
     *
     * By doing this the user has to create a new tiers on the demandeur famille
     * step
     *
     * @returns {void}
     */
    function restartDepotProcessIfUnlinkedFromTiers() {
      const hasDemandeurSet = _.get($scope.aide, 'demandeur.href') !== undefined;
      const islinkedToKnownTiers = $scope.tiers !== undefined;
      const isTemporary = demandeur && demandeur.status === 'TEMPORARY';
      const isInLinkedUsers = _.some(demandeur && demandeur.linkedUsers, (linkedUser) => {
        const isCurrentUser = linkedUser.href === $scope.currentUser.self;
        const isLinked = linkedUser.form === 'CONTRIBUTOR' || linkedUser.form === 'ADMINISTRATOR';
        return isCurrentUser && isLinked;
      });
      const hasBeenUnlinkedFromTiers = hasDemandeurSet && !islinkedToKnownTiers && (!isTemporary || !isInLinkedUsers);

      if (hasBeenUnlinkedFromTiers) {
        StoreService.demandeur.set(undefined);
        $scope.aide.demandeur = {};

        _.set($scope.aide, 'history.begin.metadata.step', 'preambule');
        _.set($scope.aide, 'history.begin.metadata.stepsStack', []);
        _.set($scope.aide, 'history.begin.metadata.stepsMetaStack', []);
      }
    }

    if ($scope.contribution) {
      if ($scope.contribution.statut === 'ANSWERED') {
        contributionsService.notifications.alreadyDone();
        return goToState('app.connected.dashboard.accueil');
      }
    }

    /**
     *
     */
    function initialize() {
      if ($stateParams.fromEchange) {
        $timeout(function () {
          $rootScope.$broadcast('showAsideEchange');
        }, 2000);
      }
      $scope.declarationCompteSignataire = declarationCompteSignataire;
      $scope.attestationDeclarationHonneur = attestationDeclarationHonneur;
      if ($scope.declarationCompteSignataire) {
        $scope.htmlHautTransmissionAttestation = _.get(
          $scope,
          'teleserviceConfiguration.workflow.pageConfirmation.htmlHautTransmissionAttestation'
        );

        $scope.htmlBasTransmissionAttestation = _.get(
          $scope,
          'teleserviceConfiguration.workflow.pageConfirmation.htmlBasTransmissionAttestation'
        );
      }
      $scope.confirmationAttestation =
        aide.status === 'WAITING_FOR_CERTIFICATE' && aide.history.begin.metadata.step === 'confirmation';
      $scope.displayInformationHeader = $translate.instant(
        ($scope.navigate.ns || 'teleservice.' + _.get(aide, 'history.begin.metadata.step')) + '.information-header'
      );

      $scope.displayInformationFooter = $translate.instant(
        ($scope.navigate.ns || 'teleservice.' + _.get(aide, 'history.begin.metadata.step')) + '.information-footer'
      );

      // Listener pour la mise à jour du bouton Enregistrer
      $window.addEventListener('message', displayFormsState, false);
      listeners.push(displayFormsState);

      // Initialize plan financement at the beginning
      depotSimpleService.initPlanFinancementFields($scope.aide, $scope.teleserviceConfiguration);
    }

    initialize();

    /**
     * Lorsque le portail reçoit un message de type 'displayFormsState',
     * On stocke la réponse dans un tableau
     * Dès que le tableau ne possède plus de state: 'error', le bouton Enregistrer est accessible
     * Sinon, le bouton est grisé
     *
     * @param {object} msg Message reçu par le portail
     */
    function displayFormsState(msg) {
      if (_.get(msg, 'data.action') === 'displayFormsState') {
        var state = _.get(msg, 'data.state');
        var viewReference = _.get(msg, 'data.reference');

        var viewFoundInStateArray = _.find(stateViewsArray, { reference: viewReference });
        if (!viewFoundInStateArray) {
          // Nouvelle référence absente du tableau
          stateViewsArray.push({ reference: viewReference, state: state });
        } else {
          // référence déjà présente, on met juste à jour le state
          viewFoundInStateArray.state = state;
        }
      }
    }

    /**
     * Display error message and block navigation if the teleservice is not open when not on contribution depot
     *
     * @returns {boolean} true if the navigation is blocked by the function
     */
    function blockNonContributionNavigationIfTeleserviceNotOpen() {
      if (
        !$scope.contribution &&
        ($scope.aide.teleservice?.expand?.statut !== 'PUBLIE' || !teleserviceOpeningData.isOpen)
      ) {
        NotificationCenter.postMessage({
          content: $translate.instant('teleservice.errors.notOpen', {
            libelleTeleservice: $scope.aide.teleservice.title,
          }),
          variant: 'danger',
        });

        $timeout(function () {
          $scope.navigate.block = true;
        });

        return true;
      }

      return false;
    }

    /**
     * Computes if tiers is moral
     * isTiersMoral is used to display access restriction modal
     *
     * @returns {boolean} true if the tiers demandeur is moral
     */
    $scope.isTiersMoral = function () {
      return tiersFacts.famille($scope.aide?.demandeur?.expand)?.isMoral || false;
    };

    $scope.isCreation = function () {
      return _.get($scope, 'stepsWizard.steps.length') > 0 && !$scope.persistenceError && !$scope.contribution;
    };
    $scope.isContribution = function () {
      return _.get($scope, 'stepsWizard.steps.length') > 0 && !$scope.persistenceError && $scope.contribution;
    };

    $scope.hideBtnValidateOrFinish = function () {
      return (
        _.get($scope, 'navigate.ns') === 'teleservice.recapitulatif' ||
        _.get($scope, 'navigate.ns') === 'teleservice.confirmation'
      );
    };

    $scope.preventValidationOnEnter = function (event) {
      if (
        event.keyCode === 13 &&
        event.target.tagName !== 'TEXTAREA' &&
        event.target.id !== 'depotNavigateFormBtnPrevious' &&
        event.target.id !== 'depotNavigateFormBtnSave' &&
        event.target.type !== 'submit'
      ) {
        event.preventDefault();
      }
    };

    // set setting parameter for 1 document by piece
    $scope.unDocumentParPiece = _.get(publicSettingsFinancement, 'unDocumentParPiece', false);

    // initialize planFinancementPieces error
    $scope.errorPiecesLignes = {};

    var invitedUser = $stateParams.user;
    if (invitedUser && invitedUser !== $scope.currentUser.id) {
      goToState('app.connected.dashboard.accueil', {
        teleserviceNotInvited: $scope.aide.teleservice.title,
      });
    }

    blockNonContributionNavigationIfTeleserviceNotOpen();

    // Verify access for this tiers to this teleservice
    if (
      $scope.tiers &&
      _.get(teleserviceConfiguration, 'workflow.type') === 'simple' &&
      !teleservicesService.checkFamilleTeleservice($scope.tiers, teleserviceConfiguration)
    ) {
      NotificationCenter.postMessage({
        content: $translate.instant('teleservice.errors.badTiers', {
          libelleFamilleTiers: $scope.tiers.famille.title,
          libelleTeleservice: $scope.aide.teleservice.title,
        }),
        variant: 'danger',
      });

      $timeout(function () {
        $scope.navigate.block = true;
      });
    }

    // Prevent user to edit an already send demande
    if ($scope.aide.status !== 'REQUESTED' && !$scope.contribution) {
      goToState('app.connected.dashboard.accueil');
    }

    // Prepare steps that match the requirement for almost all cases
    // For more specific behavior, every controller should do its work
    $scope.getSimpleSteps = function () {
      if (!$scope.contribution) {
        return depotSimpleService.getSimpleSteps($scope.teleserviceConfiguration);
      }

      return depotSimpleService.getSimpleStepsForContribution(
        $scope.teleserviceConfiguration,
        $scope.contribution.pagesARevoir,
        $scope.contribution.typeContribution
      );
    };

    // Add begin event to entity if not present
    if (_.isEmpty(_.get($scope.aide, 'history.begin'))) {
      var steps = depotSimpleService.getSimpleSteps($scope.teleserviceConfiguration);
      depotSimpleService.initializeAideHistory(aide, steps[0]);
    }

    // On "contribution" we override the "aide" state by the "contribution" one
    if ($scope.contribution) {
      $scope.aide.history.begin.metadata = $scope.contribution.history.begin.metadata;
    }

    /**
     * Delete precision and detail if amount is 0 on each lines of the poste
     *
     * @param {object} poste poste
     * @returns {void}
     */
    function deletePrecisionAndDetails(poste) {
      _.forEach(poste.lignes, function (ligne) {
        if (!_.get(ligne, 'montant.ttc') && !_.get(ligne, 'montant.ht')) {
          ligne.commentaire = '';
          delete ligne.details;
        }
      });
    }

    /**
     * Delete all precision and detail on the plan financement when amount is 0
     *
     * @param {object} planFinancement plan financement
     * @returns {void}
     */
    function deletePrecisionAndDetailsPlanFin(planFinancement) {
      _.forEach(planFinancement, function (pf) {
        _.forEach(_.get(pf, 'depense.postes', []), function (depensePoste) {
          deletePrecisionAndDetails(depensePoste);
        });
        _.forEach(_.get(pf, 'recette.postes', []), function (recettePoste) {
          deletePrecisionAndDetails(recettePoste);
        });
      });
    }

    /**
     *
     */
    function editedRequestAlreadyTransmittedModal() {
      var scopeModal = $scope.$new();
      scopes.push(scopeModal);

      scopeModal.close = (modal) => {
        modal.$hide();
        modal.$on('modal.hide', () => {
          goToState('app.connected.dashboard.aides.demandesAides');
        });
      };

      $modal({
        scope: scopeModal,
        template: 'depot/simple/modal/modal-edited-request-already-transmitted.html',
      });
    }

    $scope.saveAide = () => {
      /* skipUpdate is set during goToStep method.
      if page is not displayed to the user we don't want to perform an update
      */
      if (skipUpdate) {
        skipUpdate = false;
        return;
      }
      const promise = aidesService
        .saveAide($scope.aide)
        .then((newAide) => {
          const attributesToUpdate = [
            // computed datas, we have to update the aide
            'multiFinanceur',
            'optionsMultiFinanceur',
            // Updates 'specifiques' data on local aide to maintain compatibility with legacy expressions
            // 'specifiques' properties are calculated on the back-end so we have to update them client side.
            // exemple: find(aide.specifiques,'reference','TR_ACHAT_SECOND_VEHICULE').value===true
            'specifiques',
            'date',
          ];

          for (const attribute of attributesToUpdate) {
            const updatedValue = newAide?.[attribute];
            if (updatedValue) $scope.aide[attribute] = updatedValue;
          }

          $rootScope.isPFLoading = false;
          return newAide;
        })
        .catch((err) => {
          const data = err?.data ?? {};
          const status = err?.status;

          if (status === 403) {
            if (data.code === 403.1 && data.maxPersistenceNumber) {
              goToState('app.connected.dashboard.accueil', {
                maximumPersistenceReach: true,
                activeTab: 'demandes',
              });
              // rethrow to avoid next screens to load as if the aide was created
              // no error will be displayed because the page changes
              throw err;
            } else {
              NotificationCenter.postMessage({
                content: $translate.instant('connected.depot.errors.demandeNoLongerAccessible'),
                variant: 'danger',
              });

              goToState('app.connected.dashboard.accueil');
            }
          } else if (status === 409) {
            editedRequestAlreadyTransmittedModal();
          } else if (data.message) {
            $log.error(data.message);
          }
        })
        .finally(() => {
          $rootScope.isPFLoading = false;
        });

      StoreService.depot.pendingPromises.push(promise);
      return promise;
    };

    /**
     * Replace current demandeur particulier with a tiers particulier already linked to user (comptes multi-tiers)
     *
     * @returns {void}
     */
    function replaceDemandeurIfTiersPhysique() {
      // Ensure that the demandeur will not see demandeur creation steps if editing an existing demande
      const aideSteps = _.get($scope.aide, 'history.begin.metadata.stepsStack', []);
      const currentStep = _.get($scope.aide, 'history.begin.metadata.step');
      const stepsMetaStack = _.get($scope.aide, 'history.begin.metadata.stepsMetaStack', []);
      const pageInformationsDemandeur = $scope.teleserviceConfiguration.workflow.pageInformationsDemandeur;
      const { areInfosCompActive } = tiersService.areInfosCompTiersActive(
        pageInformationsDemandeur,
        $scope.teleserviceConfiguration,
        demandeur || $scope.tiers,
        $scope.tiers
      );

      let demandeurCreationStepsToRemove = DEMANDEUR_CREATION_STEPS;
      // If info complementaires are active, do not remove the page even if the tier is defined
      if (areInfosCompActive) {
        demandeurCreationStepsToRemove = demandeurCreationStepsToRemove.filter(
          (step) => step !== 'demandeur-complementaire'
        );
      }

      const isOnDemandeurCreationStep = currentStep && demandeurCreationStepsToRemove.includes(currentStep);
      const aideHasDemandeurCreationSteps = aideSteps.some((step) => demandeurCreationStepsToRemove.includes(step));

      if (isOnDemandeurCreationStep || aideHasDemandeurCreationSteps) {
        const firstDemandeurStepIndex = aideSteps.findIndex((step) => demandeurCreationStepsToRemove.includes(step));
        const lastDemandeurStepIndex = aideSteps
          .map((step) => demandeurCreationStepsToRemove.includes(step))
          .lastIndexOf(true);
        const stepsToRemove = lastDemandeurStepIndex === -1 ? 0 : lastDemandeurStepIndex - firstDemandeurStepIndex + 1;

        // We set demandeur in store, in $scope and resolve for dashboard
        StoreService.currentTiersRef.set(demandeur.reference, true, true);
        $scope.tiers = demandeur;
        resolveService.replaceResolveByToken($state, 'tiers', demandeur);

        // remove all demandeur creation steps and redirect to 'demandeur-recapitulatif'
        aideSteps.splice(firstDemandeurStepIndex, stepsToRemove);
        stepsMetaStack.splice(firstDemandeurStepIndex, stepsToRemove);
        _.set($scope.aide, 'history.begin.metadata.step', 'demandeur-recapitulatif');
      }
    }

    /// Persistence
    {
      // Manage step navigations
      $scope.$watch(
        '[aide.history.begin.metadata.step, aide.history.begin.metadata.stepMetadata]',
        ([newStep, newStepMetadata], [oldStep, oldStepMetadata]) => {
          // label of the page
          const depotState = $state.$current;
          depotState.data = $state.$current.data || {};
          $rootScope.pageConfigurationKey =
            (teleserviceConfiguration.libelle && teleserviceConfiguration.libelle.value) ||
            teleserviceConfiguration.reference;
          depotState.data.title = 'teleservice.' + newStep + '.title';

          if (!['financeur', 'preambule', 'confirmation'].includes(newStep) && oldStep !== 'preambule') {
            // Delete precision if amount is not set
            if (aide.history.begin.metadata.step === 'minimis') {
              deletePrecisionAndDetailsPlanFin(_.get($scope, 'aide.planFinancement', []));
            }

            //! When first loading this controller, newStep and oldStep are equal, early return to avoid saving demande when we just loaded the page
            const stepChanged = newStep !== oldStep;
            // Some screens might use the same step multiple times with only a metadata change (e.g. document comptable when pluriannuel)
            const stepMetadataChanged = !_.isEqual(newStepMetadata, oldStepMetadata);
            if (stepChanged || stepMetadataChanged) {
              // Will either create of update a aide based on whether the _id key is present
              if (!$scope.contribution) {
                $scope.saveAide();
              } else {
                contributionsService.saveContribution($scope.aide, $scope.contribution);
              }
            }
          }
          $scope.confirmationAttestation =
            aide.status === 'WAITING_FOR_CERTIFICATE' && aide.history.begin.metadata.step === 'confirmation';

          //Gestion de l'affichage des vignettes, HTML haut et bas
          $scope.displayInformationHeader = $translate.instant(
            ($scope.navigate.ns || 'teleservice.' + _.get(aide, 'history.begin.metadata.step')) + '.information-header'
          );

          $scope.displayInformationFooter = $translate.instant(
            ($scope.navigate.ns || 'teleservice.' + _.get(aide, 'history.begin.metadata.step')) + '.information-footer'
          );

          var vignette = $translate.instant(
            ($scope.navigate.ns || 'teleservice.' + aide.history.begin.metadata.step) + '.informations.content'
          );

          $scope.showHeader = !(
            _.includes($scope.displayInformationHeader, 'information-header') ||
            _.isEmpty($scope.displayInformationHeader)
          );

          $scope.showFooter = !(
            _.includes($scope.displayInformationFooter, 'information-footer') ||
            _.isEmpty($scope.displayInformationFooter)
          );

          $scope.showVignette = !(_.includes(vignette, 'informations.content') || _.isEmpty(vignette));

          // broadcast that content changed for scroll-behind-footer directive to refresh its listeners
          $scope.$broadcast('contentChanged');
        }
      );

      // Add the reference in the url when the aide is created by progressOrCreate
      // and set the teleservice id by its revision
      $scope.$watch('aide.teleservice.href', (newReference, oldReference) => {
        if (newReference !== oldReference) {
          // wait for all pending promises before continue
          $state.transitionTo(
            'app.connected.config.depot.simple',
            {
              p: $scope.aide.reference,
              configurationId: newReference.split('/').pop().split('?').shift(),
            },

            {
              location: true,
              inherit: true,
              notify: false,
            }
          );
        }
      });
    }

    /// Navigation
    {
      $scope.navigate.previous = function () {
        return $q.resolve().then(executeCustomBeforePreviousActions).then(executeCommonPreviousActions);
      };

      // Small helper function for the 'next' children navigation functions
      $scope.goToStep = function (step, forget, back, force, metadata) {
        const oldStep = $scope.aide.history.begin.metadata.step;
        const stepChanged = $scope.aide.history.begin.metadata.step != step;
        if (forget && oldStep !== 'criteres') skipUpdate = true;
        let currentStep;
        if ($scope.contribution && !force && step !== 'confirmation' && stepChanged) {
          // if back is true, the call is coming from a completude error so we directly go the step
          // otherwise we look for the next step
          const nextStep = back ? step : contributionsFactory.getNextStep($scope.contribution, step);
          $scope.goToStep(nextStep, forget, back, true);
        } else if ($scope.checkNextStep()) {
          $scope.$emit('hideModalsAndErrors');
          // The forget parameter can be used to skip adding the step to the steps history stack
          // Usefull when a step wants to skip itself entirely
          if (!forget && !back) {
            const currentStepsStack = $scope.aide.history.begin.metadata.stepsStack;
            currentStep = $scope.aide.history.begin.metadata.step;
            if (currentStep) {
              currentStepsStack.push(currentStep);
            }
            let currentStepsMetaStack = $scope.aide.history.begin.metadata.stepsMetaStack;
            if (!currentStepsMetaStack) {
              currentStepsMetaStack = [];
            }
            const currentStepMetadata = $scope.aide.history.begin.metadata.stepMetadata;
            currentStepsMetaStack.push(currentStepMetadata);
            $scope.aide.history.begin.metadata.stepsMetaStack = currentStepsMetaStack;
          }
          // Back indicates that we're jumping to a previous step (probably from a link in recapitulatif)
          // We need to update the steps stack to keep it consistent
          if (back) {
            const stepPosition = _.indexOf($scope.aide.history.begin.metadata.stepsStack, step);
            if (stepPosition !== -1) {
              // Remove all steps after the target
              $scope.aide.history.begin.metadata.stepsStack.splice(stepPosition);
            }
          }
          // Recuperation de la step courante
          currentStep = _.get($scope.aide.history, 'begin.metadata.step');
          // Initialisation du tableau si inexistant des step déja visité si inexistant
          if (!_.get($scope.aide.history.begin, 'metadata.stepsVisited')) {
            $scope.aide.history.begin.metadata.stepsVisited = [];
          } else if (currentStep) {
            // Recuperation de la position du step dans le tableau déja visité
            const stepPositionVisited = _.indexOf($scope.aide.history.begin.metadata.stepsVisited, currentStep);
            // Si non présent
            if (stepPositionVisited === -1) {
              // On l'ajoute
              $scope.aide.history.begin.metadata.stepsVisited.push(currentStep);
            }
          }

          $scope.aide.history.begin.metadata.step = step;
          $scope.aide.history.begin.metadata.stepMetadata = metadata;
          if (stepChanged) {
            $scope.cleanNavigate();
          }
          // Prevent keeping the scroll offset accross pages
          $window.scrollTo(0, 0);
        }
      };

      // Same as gotToStep but returns a function for later execution, makes it possible to use oneliners for navigate.next definitions
      $scope.goToStepFn = function (step, metadata) {
        return function (forget, back) {
          $scope.goToStep(step, forget, back, false, metadata);
        };
      };

      $scope.showErrorsOnNavigate = () => {
        // Si l'option controleCompletudeDepot est active et que le tableau de step visited est initialisé
        if (
          _.get($scope.aide.history.begin, 'metadata.stepsVisited') &&
          _.get($scope, 'teleserviceConfiguration.controleCompletudeDepot', false)
        ) {
          // Recuperation de la position du step courant dans le tableau déja visité
          const stepPositionVisited = _.indexOf(
            $scope.aide.history.begin.metadata.stepsVisited,
            _.get($scope.aide.history, 'begin.metadata.step')
          );

          // Si non présent
          if (stepPositionVisited !== -1) {
            return true;
          }
        }
        return false;
      };

      /**
       * Check if we can go to the next or previous step
       *
       * @returns {boolean} true if we can go to the next or previous step, false otherwise
       */
      $scope.checkNextStep = () => {
        if ($scope.hasErrorsExceptRequired()) return false;

        return !blockNonContributionNavigationIfTeleserviceNotOpen();
      };
    }

    /**
     * Execute the custom actions before previous common actions if defined
     *
     * @returns {*} the return value of the custom actions before previous (Promise or not) or undefined if not defined
     */
    function executeCustomBeforePreviousActions() {
      if ($scope.navigate.beforePrevious) {
        return $scope.navigate.beforePrevious();
      }
    }

    /**
     * Execute the common previous actions
     *
     * @returns {void}
     */
    function executeCommonPreviousActions() {
      $rootScope.isPFLoading = true;

      if ($scope.checkNextStep()) {
        return saveTiersIfNeeded().then(goToPreviousStep);
      }
    }

    /**
     * Update aide metadata and go to previous step
     *
     * @returns {void}
     */
    function goToPreviousStep() {
      $scope.$emit('hideModalsAndErrors');

      if (
        !$scope.aide.history.begin.metadata.stepsVisited.includes($scope.aide.history.begin.metadata.step) &&
        $scope.aide.history.begin.metadata.step !== 'criteres'
      )
        $scope.aide.history.begin.metadata.stepsVisited.push($scope.aide.history.begin.metadata.step);

      // Get to last element of the stack of steps and remove it
      const previousStep = $scope.aide.history.begin.metadata.stepsStack.pop();
      const stepChange = $scope.aide.history.begin.metadata.step != previousStep;
      $scope.aide.history.begin.metadata.step = previousStep;
      $scope.aide.history.begin.metadata.stepMetadata = $scope.aide.history.begin.metadata.stepsMetaStack.pop();

      if (stepChange) {
        $scope.cleanNavigate();
      }

      $scope.navigate.metadata = $scope.aide.history.begin.metadata.stepMetadata;

      $window.scrollTo(0, 0);
    }

    /**
     * Save the demandeur or beneficiaire if needed depending on the current step
     *
     * @returns {Promise} promise of the tiers saving or empty if no tiers needs to be saved
     */
    function saveTiersIfNeeded() {
      // we should save the demandeur or beneficiaire if hitting "previous" on a creation step
      let promise;

      const currentStep = $scope.aide.history.begin.metadata.step;
      const shouldSaveDemandeur = _.includes(DEMANDEUR_CREATION_STEPS, currentStep) && !isDemandeurReadOnly;
      const shouldSaveBeneficiaire = _.includes(BENEFICIAIRE_CREATION_STEPS, currentStep);

      if (shouldSaveDemandeur) {
        promise = $scope.saveDemandeur();
      } else if (shouldSaveBeneficiaire) {
        promise = $scope.saveBeneficiaire();
      } else {
        promise = $q.resolve();
      }

      return promise;
    }

    /// Aide
    {
      // Standard configurations
      const createConfiguration = (entity, step) => {
        const mergedConfiguration = _.merge(
          {
            ns: 'teleservice.' + step,
            displayCharactersLeft: true,
            remoteValidation: _.get(teleserviceConfiguration, 'controleCompletudeDepot', false),
          },

          simpleConfiguration[step],
          _.get(teleserviceConfiguration, entity + '.' + step)
        );

        return mergedConfiguration;
      };

      // Load in scope the view configurations for all steps
      const loadConfigurations = () => {
        $scope.demandeurBeneficiaireConfiguration = simpleConfiguration['demandeur-beneficiaire'];
        $scope.identificationDemandeurConfiguration = createConfiguration('demandeur', 'demandeur-identification');
        $scope.thematiquesDemandeurConfiguration = createConfiguration('demandeur', 'demandeur-thematiques');
        _.merge($scope.thematiquesDemandeurConfiguration, simpleConfiguration.thematiques);
        $scope.adresseDemandeurConfiguration = createConfiguration('demandeur', 'demandeur-adresse');
        $scope.informationsComplementairesDemandeurConfiguration = createConfiguration(
          'demandeur',
          'demandeur-complementaire'
        );

        $scope.representantLegalDemandeurConfiguration = createConfiguration(
          'demandeur',
          'demandeur-representant-legal'
        );

        $scope.representantsDemandeurConfiguration = createConfiguration('demandeur', 'demandeur-representants');
        $scope.familleBeneficiaireConfiguration = createConfiguration('beneficiaire', 'beneficiaire-famille');
        $scope.familleDemandeurConfiguration = createConfiguration('demandeur', 'demandeur-famille');
        $scope.identificationBeneficiaireConfiguration = createConfiguration(
          'beneficiaire',
          'beneficiaire-identification'
        );

        $scope.thematiquesBeneficiaireConfiguration = createConfiguration('beneficiaire', 'beneficiaire-thematiques');
        _.merge($scope.thematiquesBeneficiaireConfiguration, simpleConfiguration.thematiques);
        $scope.adresseBeneficiaireConfiguration = createConfiguration('beneficiaire', 'beneficiaire-adresse');
        $scope.domiciliationBancaireConfiguration = createConfiguration('aide', 'domiciliation-bancaire');
        $scope.informationsComplementairesBeneficiaireConfiguration = createConfiguration(
          'beneficiaire',
          'beneficiaire-complementaire'
        );

        //Controls screen "Représentant légal du bénéficiaire"
        $scope.representantLegalBeneficiaireConfiguration = createConfiguration(
          'beneficiaire',
          'beneficiaire-representant-legal'
        );

        //Controls screen "Contacts du bénéficiaire"
        $scope.representantsBeneficiaireConfiguration = createConfiguration(
          'beneficiaire',
          'beneficiaire-representants'
        );

        $scope.informationsGeneralesConfiguration = createConfiguration('aide', 'informations-generales');
        _.merge(
          $scope.informationsGeneralesConfiguration.fields,
          _.keyBy(
            aide.teleservice?.expand?.workflow?.simple?.pageInformationsGenerales?.demandeFinancement?.fields ??
              teleserviceConfiguration.workflow?.pageInformationsGenerales?.demandeFinancement?.fields,
            'reference'
          )
        );

        _.merge(
          $scope.informationsGeneralesConfiguration.fields,
          _.keyBy(
            _.get(teleserviceConfiguration, 'workflow.pageInformationsGenerales.realisationEtEvaluation.fields'),
            'reference'
          )
        );

        $scope.pageFinanceurConfiguration = createConfiguration('aide', 'financeur');
        $scope.recapitulatifAideConfiguration = createConfiguration('aide', 'recapitulatif');
        $scope.criteresConfiguration = createConfiguration('aide', 'criteres');
        $scope.confirmationAideConfiguration = createConfiguration('aide', 'confirmation');
        $scope.piecesConfiguration = createConfiguration('aide', 'pieces');
        $scope.documentComptableConfiguration = createConfiguration('aide', 'document-comptable');
        $scope.minimisConfiguration = createConfiguration('aide', 'minimis');
        $scope.informationsComplementaires2Configuration = createConfiguration('aide', 'pageInformationsGenerales2');
        $scope.informationsComplementaires3Configuration = createConfiguration('aide', 'pageInformationsGenerales3');
        $scope.indicateurs = createConfiguration('aide', 'indicateurs');
      };

      loadConfigurations();
    }

    /**
     * Tiers
     */
    function tiersConfigurations() {
      // Keep list of functions through several pages
      $scope.fonctionsRepresentants = {};
      $scope.setFonctionsRepresentants = (famille, tiersKey) => {
        // Request functions associated with tiers' famille
        $scope.fonctionsRepresentants[tiersKey] = _.filter(mdm.fonctionsrepres.array, (fonctionLink) => {
          return _.find(famille.fonctionsRepresentants, {
            href: fonctionLink.href,
          });
        });
      };
    }

    tiersConfigurations();

    /**
     * Close all iFrame communication managers
     */
    function closeIframeCommunicationManagers() {
      iframeCommunicationManagers.forEach((manager) => {
        manager.close();
      });
    }

    /**
     * Before save progress formation
     *
     * @param {object} formationCommunicationManager
     * @returns {void}
     */
    function beforeSaveProgressFormation(formationCommunicationManager) {
      // Thanks to saveInProgress option, the data will be considered valid and will be saved
      closeIframeCommunicationManagers();
      iframeCommunicationManagers.push(formationCommunicationManager);
    }

    /**
     * Get the iframe if the current step contains views
     *
     * @param {string} kind kind of entity
     * @returns {*} the iframe
     */
    function getViewIframe(kind) {
      let viewIFrame;

      if ($scope.aide.views || kind === 'demandeur') {
        const step = _.get($scope.aide, 'history.begin.metadata.step');
        let elements;

        // Getting the iframe, depending of the step
        switch (step) {
          case 'informations-generales':
            elements = angular.element('#viewsIframe1');
            break;
          case 'pageInformationsGenerales2':
            elements = angular.element('#viewsIframe2');
            break;
          case 'pageInformationsGenerales3':
            elements = angular.element('#viewsIframe3');
            break;
          case 'demandeur-complementaire':
            elements = angular.element('#viewsInfosComps');
            break;
        }

        if (_.size(elements) > 0) {
          viewIFrame = elements[0];
        }
      }
      return viewIFrame;
    }

    /**
     * Get the iframe if the current step contains indicateurs
     *
     * @returns {object} the iframe
     */
    function getIndicateursIframe() {
      let indicateursIframe;
      let elements;
      const step = _.get($scope.aide, 'history.begin.metadata.step');

      if (step === 'indicateurs') {
        elements = angular.element('#indicateursPrevisionnelIframe');
      }

      if (elements && _.size(elements) > 0) {
        indicateursIframe = elements[0];
      }
      return indicateursIframe;
    }

    /**
     * Validate iframes data (views, indicateurs)
     *
     * @param {string} [kind=aide] kind of entity
     * @returns {Promise<Array>} iframe validation
     */
    function validateIframes(kind = 'aide') {
      const promises = [];

      // Validate views
      promises.push(validateViews(kind));

      // Check if indicateur page is active in workflow
      const isPageIndicateursActif = $scope.teleserviceConfiguration?.workflow?.pageIndicateurs?.actif ?? false;
      // and save indicateurs
      if (isPageIndicateursActif) {
        // Validate indicateurs
        promises.push(validateIndicateurs());
      }

      // Validation
      return $q.all(promises);
    }

    /**
     * Validate "informations complémentaires" data (forms, forms-common or master-details views).
     *
     * @param {string} [kind=aide] kind of entity
     * @returns {Promise<void>} "informations complémentaire" promise
     */
    function validateViews(kind = 'aide') {
      const viewIframe = getViewIframe(kind);

      if (!viewIframe) {
        return $q.resolve();
      }

      const validateViewsListeners = [];
      return $q((resolve, reject) => {
        /**
         * Listener for "viewsUpdated" event emitted by the view iFrame when a view validation is OK.
         *
         * @param {object} msg message
         * @returns {void}
         */
        const onViewsUpdated = (msg) => {
          if (_.get(msg, 'data.action') === 'viewsUpdated') {
            const index = _.get(msg, 'data.index');
            const values = _.get(msg, 'data.values');
            _.set($scope, `${kind}.views[${index}].values`, values);
          }
        };
        $window.addEventListener('message', onViewsUpdated, false);
        validateViewsListeners.push(onViewsUpdated);

        /**
         * Listener for "updateStateViews" event emitted by the view iFrame when every views validation is completed.
         *
         * @param {object} msg message
         * @returns {void}
         */
        const onUpdateStateViews = (msg) => {
          if (_.get(msg, 'data.action') === 'updateStateViews') {
            if (_.get(msg, 'data.state') === 'error') {
              reject(msg.data);
            } else if (_.get(msg, 'data.state') === 'ok') {
              const { urlEntity, formsCommonValues, views } = msg.data;
              $q.resolve(viewsService.manageFormsCommonValues(urlEntity, formsCommonValues, views));
              resolve();
            }
          }
        };
        $window.addEventListener('message', onUpdateStateViews, false);
        validateViewsListeners.push(onUpdateStateViews);

        const messageData = {
          action: 'validViews',
          options: { skipRequiredErrors: true, showAllErrors: false },
        };

        // we send a patch when saving tiers famille infos-comp
        if (kind === 'demandeur') {
          messageData.options.patchViewsEntity = true;
        }

        // Ask views validation to view iFrame.
        viewIframe.contentWindow.postMessage(messageData, '*');
      }).finally(() => {
        _.each(validateViewsListeners, (listener) => {
          $window.removeEventListener('message', listener);
        });
      });
    }

    /**
     * Validate indicateurs iframe
     *
     * @returns {Promise<void>} Resolve or reject when validation is done
     */
    function validateIndicateurs() {
      const indicateursIframe = getIndicateursIframe();

      if (!indicateursIframe) {
        return $q.resolve();
      }

      const validateIndicateursListeners = [];
      return $q((resolve, reject) => {
        /**
         * Listener for "updateStateIndicateurs" event emitted by the indicateurs iFrame
         * when every indicateurs validation is completed.
         *
         * @param {object} msg message
         * @returns {void}
         */
        const onUpdateIndicateurs = (msg) =>
          indicateursService.updateIndicateursSaisisOnAide(msg, resolve, reject, $scope.aide);

        $window.addEventListener('message', onUpdateIndicateurs, false);
        validateIndicateursListeners.push(onUpdateIndicateurs);

        // Ask indicateurs validation to view iFrame.
        const messageData = {
          action: 'validateIndicateursSaisis',
          options: { saveInProgress: true },
        };

        indicateursIframe.contentWindow.postMessage(messageData, '*');
      }).finally(() => {
        _.each(validateIndicateursListeners, (listener) => {
          $window.removeEventListener('message', listener);
        });
      });
    }

    /**
     * Show a success message when everything is correctly save
     */
    function displayAlertSuccess() {
      $scope.alerts = alertsService.getAlertSuccess('connected.config.depot.success.save');
      hideAlertAfterTimeout($scope.alerts);
    }

    /**
     * Show an error message when an error occurred while trying to save the "aide"
     *
     * @param {string} err error message
     */
    function displayAlertError(err) {
      $log.error("Une erreur est survenue lors de l'enregistrement de la demande", err);
    }

    $scope.saveProgress = (depotForm) => {
      return $q
        .resolve()
        .then(() => {
          return executeCommonSaveActions(depotForm);
        })
        .then(() => {
          return executeCustomAfterSaveActions();
        })
        .catch((err) => {
          throw err;
        });
    };

    /**
     * Execute the common previous actions
     *
     * @param {object} depotForm
     * @returns {Promise}
     */
    function executeCommonSaveActions(depotForm) {
      const step = _.get($scope.aide, 'history.begin.metadata.step');

      if (!$scope.hasErrorsExceptRequired(depotForm.$error)) {
        const currentStep = _.get($scope, 'aide.history.begin.metadata.step');
        let tiersToSave;
        let isDemandeur = false;
        let isBeneficiaire = false;
        if (DEMANDEUR_CREATION_STEPS.includes(currentStep)) {
          tiersToSave = StoreService.demandeur.get();
          isDemandeur = true;
        } else if (BENEFICIAIRE_CREATION_STEPS.includes(currentStep)) {
          tiersToSave = StoreService.beneficiaire.get();
          isBeneficiaire = true;
        }

        if ($scope.contribution) {
          return bourseService
            .saveProgressFormation($scope.teleserviceConfiguration, $scope.aide, beforeSaveProgressFormation)
            .then(() => validateIframes())
            .then(() => contributionsService.saveContribution($scope.aide, $scope.contribution))
            .then(displayAlertSuccess)
            .catch((err) => {
              displayAlertError(err);
            });
        } else if (tiersToSave) {
          if (step === 'demandeur-complementaire') {
            // this screen works with partial updates (JSON PATCH)
            // eventually, all screens should manage JSON PATCH
            const patches = StoreService.tiers.patches.get();
            const patchPromise = _.isEmpty(patches)
              ? $q.resolve()
              : tiersService.patchTiers(tiersToSave.reference, patches, $scope.mdm).then((patchedTiers) => {
                  // we always need the family expand in depot workflow
                  patchedTiers.famille = tiersToSave.famille;
                  StoreService.demandeur.set(patchedTiers);
                  StoreService.tiers.patches.clean();
                });

            return patchPromise
              .then(() => aidesService.saveAide($scope.aide))
              .then(() => validateIframes('demandeur'))
              .then(displayAlertSuccess)
              .catch(displayAlertError);
          } else {
            return bourseService
              .saveProgressFormation($scope.teleserviceConfiguration, $scope.aide, beforeSaveProgressFormation)
              .then(() =>
                saveTiers(tiersToSave, !isDemandeur).then((savedTiers) => {
                  // we always need the family expand in depot workflow
                  savedTiers.famille = tiersToSave.famille;
                  if (isDemandeur) {
                    StoreService.demandeur.set(savedTiers);
                  } else if (isBeneficiaire) {
                    StoreService.beneficiaire.set(savedTiers);
                  }
                })
              )
              .then(() => aidesService.saveAide($scope.aide))
              .then(() => validateIframes())
              .then(displayAlertSuccess)
              .catch((err) => {
                displayAlertError(err);
              });
          }
        } else {
          return bourseService
            .saveProgressFormation($scope.teleserviceConfiguration, $scope.aide, beforeSaveProgressFormation)
            .then(() => validateIframes())
            .then(() => aidesService.saveAide($scope.aide))
            .then(displayAlertSuccess)
            .catch((err) => {
              displayAlertError(err);
            });
        }
      }
      return $q.resolve(true);
    }

    /**
     * Execute the custom actions save after common actions if defined
     *
     * @returns {*} the return value of the custom actions save after (Promise or not) or undefined if not defined
     */
    function executeCustomAfterSaveActions() {
      if ($scope.navigate.afterSave) {
        return $scope.navigate.afterSave();
      }
    }

    //Check if error list (without required) is empty
    $scope.hasErrorsExceptRequired = (errorList) => {
      if (!errorList && $scope.depotForm) errorList = $scope.depotForm.$error;
      return errorList ? !_.isEmpty(_.pull(_.keys(errorList), 'required')) : false;
    };

    // Are we on a page that allows "save"
    $scope.canSaveDemande = () => {
      const noSaveBtnPages = ['preambule', 'criteresEligibilite', 'recapitulatif', 'financeur', null];

      // we want to display the save button for the 'tiers' pages, excepted the demandeur-recapitulatif one
      const step = _.get($scope, 'aide.history.begin.metadata.step');
      const isDemandeurRecapitulatif = step === 'demandeur-recapitulatif';

      return !_.includes(noSaveBtnPages, $scope.stepsWizard.active) && !isDemandeurRecapitulatif;
    };

    /**
     * Save the demandeur
     *
     * @param {object} options options
     * @returns {Promise} tiers promise
     */
    $scope.saveDemandeur = function saveDemandeur(options) {
      const storedDemandeur = StoreService.demandeur.get();
      return saveTiers(storedDemandeur, false, options);
    };

    /**
     * Save the beneficiaire
     *
     * @param {object} options options
     * @returns {Promise} tiers promise
     */
    $scope.saveBeneficiaire = function saveBeneficiaire(options) {
      const storedBeneficiaire = StoreService.beneficiaire.get();
      return saveTiers(storedBeneficiaire, true, options);
    };

    /**
     * Returns if the tiers should be saved
     * If it is found in the referentiel with a status that is not TEMPORARY, then it should
     * not be saved because it should not be updated during depot
     *
     * @param {object} tiers tiers
     * @returns {boolean} true if the tiers should be saved, false otherwise
     */
    function shouldSaveTiers(tiers) {
      if (!tiers.reference) {
        return $q.resolve(true);
      }

      return tiersService
        .findTiers({ $filter: `reference eq '${tiers.reference}'`, $select: 'status' })
        .then((savedTiers) => {
          const savedTiersStatus = Array.isArray(savedTiers) && savedTiers[0] && savedTiers[0].status;
          return !savedTiersStatus || savedTiersStatus === 'TEMPORARY';
        });
    }

    /**
     * Save the given tiers
     *
     * @param {object} tiers tiers to save
     * @param {boolean} isBeneficiaire if the tiers is beneficiaire
     * @param {object} [options={}] options
     * @param {boolean} [options.refreshExternalData=false] if the external data should be refreshed
     * @returns {Promise} tier that has been saved
     */
    function saveTiers(tiers, isBeneficiaire = false, options = {}) {
      const { refreshExternalData = false } = options;

      return shouldSaveTiers(tiers).then((shouldSave) => {
        if (shouldSave) {
          const cleanedTiers = tiers.getCleanEntity();

          const savingThematiques = _.map(cleanedTiers.thematiquesLiees, (thematique, name) => {
            if (!_.isEmpty(thematique)) {
              // The api only accepts thematiques in kebabCase (currently they are in camelCase)
              const collectionName = _.kebabCase(name);
              return tiersThematiquesService.saveThematique(collectionName, thematique, tiers);
            }
          });

          return $q.all(savingThematiques).then(() => {
            let options;

            if (refreshExternalData) {
              options = { headers: { 'X-refresh-external-data': 'true' } };
            }

            return tiersService.saveTiers(cleanedTiers, $scope.mdm, options).then((savedTiers) => {
              // keep the expanded family if exists and update store value
              savedTiers.famille = tiers.famille;
              if (isBeneficiaire) {
                StoreService.beneficiaire.set(savedTiers);
                $scope.aide.beneficiaires[0] = {
                  href: savedTiers.id,
                  title: savedTiers.title,
                  expand: savedTiers,
                };
              } else {
                StoreService.demandeur.set(savedTiers);
                $scope.aide.demandeur = {
                  href: savedTiers.href,
                  title: savedTiers.title,
                  expand: savedTiers,
                };
              }
              return savedTiers;
            });
          });
        } else {
          return $q.resolve();
        }
      });
    }

    /**
     * Check if previous button should be displayed
     *
     * @returns {boolean} true if the button should be displayed false otherwise
     */
    $scope.displayPreviousButton = () => {
      const stepsStack = _.get(aide, 'history.begin.metadata.stepsStack', []);
      const step = _.get(aide, 'history.begin.metadata.step');
      return stepsStack.length > 0 && !['confirmation', 'preambule'].includes(step);
    };

    /**
     * Determines if the share button should be visible or not
     * We want to show the button as disabled at the start of the workflow (first condition)
     * The "canBeShared" rule from aidesService is applied after
     *
     * @returns {boolean} true if shared button is displayed
     */
    $scope.showShareButton = () => !aide.id || aidesService.canBeShared($scope.aide);

    const isUserDemandeur = () => {
      return aide.history.begin.user.href === $scope?.currentUser?.self;
    };

    /**
     * Determines if the restrictions button should be visible or not. It is visible when :
     *  - The demande is for a tiers MORAL
     *  - Page is RECAPITULATIF
     *  - Actual user is demandeur
     * ! signataire can modify demande, and then become demandeur, this is KNOWN and INTENDED
     *
     * @returns {boolean} true if restrictions button is displayed
     */
    $scope.showRestrictionsButton = () => {
      return $scope.isTiersMoral() && aide.history.begin.metadata.step === 'recapitulatif' && isUserDemandeur();
    };

    // Update aide referenceAdministrative and status after aide had been transmitted
    $rootScope.$on('aide-transmitted', ($event, args) => {
      if ($scope.aide) {
        $scope.aide.referenceAdministrative = args.referenceAdministrative;
        $scope.aide.status = args.status;
      }
    });

    // When demande is shared, lock demande by connecting to socket if not already connected
    $scope.$on('aide-shared', () => {
      if (!ctrl.lockingSocket) {
        lockAidesService.getLockingSocket(aide).then((socket) => {
          ctrl.lockingSocket = socket;
        });
      }
    });

    $scope.$on('$destroy', () => ctrl.onDestroy());

    /**
     * Check if we are in preambule or confirmation steps
     *
     * @returns {boolean} true if we are in preambule or confirmation step false otherwise
     */
    $scope.isStepPreambuleOrConfirmation = () => {
      return (
        $scope.aide.history.begin.metadata.step === 'confirmation' ||
        $scope.aide.history.begin.metadata.step === 'preambule'
      );
    };

    /**
     *
     */
    function resetDepotForm() {
      $scope.depotForm.attempted = false;
      // Reset dirty, pristine and untouched state on form
      $scope.depotForm.$setPristine();
      $scope.depotForm.$setUntouched();
    }

    /**
     * React to depotForm submit. This means this function is trigerred every time we click on "Next" in each page of type "form"
     * ? "noform" navigation does not trigger submit event
     *
     * @returns {void}
     */
    $scope.onFormSubmit = () => {
      const controleCompletudeDepot = publicSettingsFinancement.teleservice?.controleCompletudeDepot ?? false;
      const isDepotValid = $scope.depotForm.$valid;

      if (isDepotValid || (controleCompletudeDepot && !$scope.hasErrorsExceptRequired())) {
        resetDepotForm();

        if ($scope.navigate.validate) $scope.navigate.validate();
        else if ($scope.navigate.finish) $scope.navigate.finish();
        else $scope.navigate.next();
      }
    };
  },
]);
