'use strict';
/**
 * @module portailDepotDemandeAide
 * @name demandePaiementController
 * @description
 *
 *   This controller manages a series of page for 'demandePaiement' model of procedure
 */

/*jshint maxparams:false */
angular.module('portailDepotDemandeAide.depot').controller('demandePaiementController', [
  '$scope',
  '$rootScope',
  '$window',
  '$state',
  '$stateParams',
  '$log',
  '$location',
  'demandesAidesService',
  'demandesPaiementService',
  'userSessionService',
  'suiviFinancementService',
  'indicateursService',
  'aide',
  'typesPaiement',
  'demandePaiement',
  'informationsComplementaires',
  'AUTHENTICATION_EVENTS',
  'configuration',
  'decisions',
  'alertsService',
  'StoreService',
  function (
    $scope,
    $rootScope,
    $window,
    $state,
    $stateParams,
    $log,
    $location,
    demandesAidesService,
    demandesPaiementService,
    userSessionService,
    suiviFinancementService,
    indicateursService,
    aide,
    typesPaiement,
    demandePaiement,
    informationsComplementaires,
    AUTHENTICATION_EVENTS,
    configuration,
    decisions,
    alertsService,
    StoreService
  ) {
    'use strict';

    $scope.aide = aide;
    $scope.decisions = decisions;

    if ($stateParams.dossier) {
      const ligneDossier = suiviFinancementService.getLigneWithDossier(aide, $stateParams.dossier);
      $scope.dossierFinancement = {
        href: _.get(ligneDossier, 'financement.source.href'),
        referenceAdministrative: _.get(ligneDossier, 'financement.source.referenceAdministrative'),
        title: _.get(ligneDossier, 'financement.source.title'),
        montantVote:
          _.get(ligneDossier, 'financement.montantVote.ttc') || _.get(ligneDossier, 'financement.montantVote.ht') || 0,
      };
    }

    if ($scope.aide.multiFinanceur) {
      $scope.financeur = _.find($scope.aide.multiFinancement.financeurs, ['href', $stateParams.financeurHref]);
    }

    $scope.demandePaiement = demandePaiement;
    $scope.demandePaiement.demandeFinancement = $scope.demandePaiement.demandeFinancement || {
      href: aide.id,
      expand: aide,
    };

    if ($scope.demandePaiement?.statut !== 'EN_COURS') {
      // if demande paiement is already transmitted, we rediect to recap page
      $scope.redirectToRecap = true;
      $state.go('app.connected.dashboard.aides.demandesPaiement.recapitulatif', {
        reference: $scope.demandePaiement.demandeFinancement.expand.reference,
        referencePaiement: $scope.demandePaiement.reference,
        teleservicePaiement: ($scope.demandePaiement.teleservicePaiement.id || '').split('/').pop(),
        financeur: _.get($scope, 'financeur.href'),
      });
    } else {
      $scope.redirectToRecap = false;
    }

    /**
     * Méthode qui permet de récupérer la ligne de financement du financeur
     *
     * @param  {object} aide Aide
     * @param  {object} financeurHref financeurHref
     * @returns {object}      Ligne de financement
     */
    var findLigneFinancementFinanceur = function (aide, financeurHref) {
      var lignePF;
      _.each(aide.planFinancement, function (planFinancement) {
        var postes = _.get(planFinancement, 'recette.postes');
        _.each(postes, function (poste) {
          _.each(_.get(poste, 'lignes'), function (ligne) {
            if (_.get(ligne, 'financement.financeur.href') === financeurHref) {
              lignePF = ligne;
            }
          });
        });
      });
      return lignePF;
    };

    if (demandesAidesService.isMultiFinanceursWithoutFinanceurPrincipal(aide)) {
      // 'financeur.href' (si déjà un paiement sur ce financeur), sinon 'financeurHref'
      var financeurHref = _.get($stateParams, 'financeurHref');
      var lignePF = findLigneFinancementFinanceur(aide, financeurHref);
      $scope.demandePaiement.dossierFinancement = {
        href: _.get(lignePF, 'financement.source.href'),
      };
    } else {
      const dossierFinancementHref = $scope.dossierFinancement
        ? $scope.dossierFinancement.href
        : JSONPath(
            '$.demandeFinancement.expand.planFinancement..recette..lignes..[?(@.financement && @.financement.source && @.financement.source.href)].financement.source.href',
            $scope.demandePaiement
          )[0];
      $scope.demandePaiement.dossierFinancement = {
        href: dossierFinancementHref,
      };
    }

    $scope.demandePaiement.linkedUsers = $scope.demandePaiement.linkedUsers || [];

    // Add begin event to entity if not present
    if (!_.get($scope.demandePaiement, 'history.begin')) {
      $scope.demandePaiement.history = {
        begin: {
          summary: 'Creation of the entity - Status ' + $scope.demandePaiement.statut,
          user: {
            href: configuration.user.accountManagement + /users/ + _.get(userSessionService.getUser(), 'userName'),
            method: 'GET',
          },

          date: new Date(),
          metadata: {
            step: 'preambule',
            stepsStack: [],
          },
        },
      };

      var user = {
        title: $scope.currentUser.displayName,
        rel: 'ADMINISTRATOR',
        href: configuration.user.accountManagement + /users/ + $scope.currentUser.userName,
        method: 'GET',
        form: 'ADMINISTRATOR',
      };

      $scope.demandePaiement.linkedUsers.push(user);
    }

    /// Persistence

    // Manage step navigations
    $scope.$watch('demandePaiement.history.begin.metadata.step', function (newStep) {
      $scope.actif = false;
      $scope.newStep = newStep;

      // label of the page
      var depotState = $state.$current;
      depotState.data = $state.$current.data || {};
      $rootScope.pageConfigurationKey =
        ($scope.teleserviceConfiguration.libelle && $scope.teleserviceConfiguration.libelle.value) ||
        $scope.teleserviceConfiguration.reference;
      delete depotState.data.title;

      if (newStep && newStep !== 'preambule' && newStep !== 'confirmation') {
        // Get demande paiement dossier financement expand
        // check if aide is V8 and not not multi financeurs without financeur principal
        if (
          !demandesAidesService.isMultiFinanceursWithoutFinanceurPrincipal(aide) &&
          _.has($scope, 'demandePaiement.reference') &&
          (!_.has($scope, 'demandePaiement.dossierFinancement.expand') ||
            !_.has($scope, 'demandePaiement.attributaire.expand'))
        ) {
          var hasCorrelationsV8 = _.find(_.get(aide, 'correlations', []), function (correlation) {
            return correlation.value.startsWith('/connecteur-aides-v9v8');
          });
          //if not v8 add dispositif expand
          if (!hasCorrelationsV8) {
            const promise = demandesPaiementService
              .getDemandePaiement($scope.demandePaiement.reference, {
                params: {
                  $expand: 'dossierFinancement.dispositif,attributaire',
                },
              })
              .then(function (demandePaiement) {
                $scope.demandePaiement.dossierFinancement = _.get(demandePaiement, 'dossierFinancement');

                if (_.has(demandePaiement, 'attributaire.expand')) {
                  $scope.demandePaiement.attributaire = _.get(demandePaiement, 'attributaire');
                }
              });
            StoreService.demandePaiement.pendingPromises.push(promise);
          }
        }

        // Will either create of update a persistence based on whether the _id key is present
        $scope.continue = false;

        demandesPaiementService
          .saveDemandePaiement($scope.demandePaiement)
          .then(function (demandePaiement) {
            // Refresh attributaire if we have one with its family,
            // otherwise we will use the one coming from expand request
            if (demandePaiement.attributaire.famille) {
              $scope.demandePaiement.attributaire = demandePaiement.attributaire;
            }
            $scope.continue = true;
          })
          .catch(function (error) {
            // Disconnection of the user
            if (error && error.status === 401) {
              $state.go('app.home', {
                loggedOut: true,
              });

              userSessionService.destroy();
              $rootScope.$broadcast(AUTHENTICATION_EVENTS.notAuthorized);
              return;
            } else {
              $log.error(error);
              $rootScope.$broadcast('alerts', alertsService.getAlertError(error.data.message));
            }
          });
      }
    });

    // Add the reference in the url, when the demande is created by saveDemandePaiement
    $scope.$watch('demandePaiement.reference', function (newReference, oldReference) {
      if (newReference && !oldReference) {
        $location.search('reference', newReference);
      }
    });

    /// Navigation
    $scope.navigate.previous = function () {
      $scope.$emit('hideModalsAndErrors');
      // Get to last element of the stack of steps and remove it
      $scope.demandePaiement.history.begin.metadata.step =
        $scope.demandePaiement.history.begin.metadata.stepsStack.pop();
      $scope.cleanNavigate();
      $window.scrollTo(0, 0);
    };

    // Small helper function for the 'next' children navigation functions
    $scope.goToStep = function (step, forget) {
      $scope.$emit('hideModalsAndErrors');
      // The forget parameter can be used to skip adding the step to the steps history stack
      // Usefull when a step wants to skip itself entirely
      if (!forget) {
        $scope.demandePaiement.history.begin.metadata.stepsStack.push(
          $scope.demandePaiement.history.begin.metadata.step
        );
      }
      $scope.demandePaiement.history.begin.metadata.step = step;
      $scope.cleanNavigate();
      // Prevent keeping the scroll offset accross pages
      $window.scrollTo(0, 0);
    };

    // Same as gotToStep but returns a function for later execution, makes it possible to use oneliners for navigate.next definitions
    $scope.goToStepFn = function (step) {
      return function (forget) {
        $scope.goToStep(step, forget);
      };
    };

    $scope.activePage = function (actif) {
      $scope.actif = actif || true;
      $state.$current.data.title = 'teleservice.' + $scope.newStep + '.title';
    };

    $scope.navigate.backToDemandesPaiementList = function (demandePaiement) {
      $state.go('app.connected.dashboard.aides.demandesPaiement.list', {
        reference: demandePaiement.demandeFinancement.expand.reference,
        aide: $scope.aide,
        financeur: $scope.financeur?.href,
        teleservicePaiement: (demandePaiement.teleservicePaiement.id || '').split('/').pop(),
        configurationId: demandePaiement.teleservicePaiement.id,
        dossier: $stateParams.dossier,
      });
    };

    /**
     * Prepare steps that match the requirement for almost all cases
     * For more specific behavior, every controller should do its work
     *
     * @returns {string[]} all step progress for demande paiement
     */
    $scope.getDemandePaiementSteps = function () {
      const steps = [];
      if (_.get($scope.teleserviceConfiguration, 'workflow.pagePreambule.actif', false)) {
        steps.push('preambule');
      }
      steps.push('informationsGenerales');
      if (_.get($scope.teleserviceConfiguration, 'workflow.pageDomiciliationBancaire.actif', false)) {
        steps.push('domiciliationBancaire');
      }
      if (_.get($scope.teleserviceConfiguration, 'workflow.pagePieces.actif', false)) {
        steps.push('pieces');
      }
      steps.push('recapitulatif');
      return steps;
    };

    // Types de paiement du référentiel-financement
    $scope.typesPaiement = typesPaiement;

    $scope.informationsComplementaires = informationsComplementaires;

    // Check if all informations complementaires are agentOnly
    $scope.displayInfosComplementaires = demandesPaiementService.mustDisplayInformationsComplementaires(
      $scope.informationsComplementaires
    );

    // variable use to say if the "indicateur" page should be dispay
    $scope.displayIndicateursRealisation = false;

    // Return true if the "indicateur" page is active on the "demande paiement" workflow
    const pageIndicateurIsActif = _.get($scope, 'teleserviceConfiguration.workflow.pageIndicateurs.actif');

    indicateursService.showIndicateur(_.get($scope, 'aide')).then((displayIndic) => {
      $scope.displayIndicateursRealisation = displayIndic && pageIndicateurIsActif;
    });

    /**
     * Standard configuration
     *
     * @param {object} entity
     * @param {string} step
     * @returns {object}
     */
    function createConfiguration(entity, step) {
      return _.merge(
        {
          ns: 'teleservice.' + step,
        },

        _.get($scope.teleserviceConfiguration, entity + '.' + step)
      );
    }
    $scope.preambuleConfiguration = createConfiguration('demandePaiement', 'preambule');
    $scope.informationsGeneralesConfiguration = createConfiguration('demandePaiement', 'informations-generales');
    $scope.informationsComplementairesConfiguration = createConfiguration(
      'demandePaiement',
      'informations-complementaires'
    );

    $scope.indicateursRealisationConfiguration = createConfiguration('demandePaiement', 'indicateurs');
    $scope.documentComptableConfiguration = createConfiguration('demandePaiement', 'document-comptable');
    $scope.domiciliationBancaireConfiguration = createConfiguration('aide', 'domiciliation-bancaire');
    $scope.piecesConfiguration = createConfiguration('demandePaiement', 'pieces');
    $scope.recapitulatifConfiguration = createConfiguration('demandePaiement', 'recapitulatif');
    $scope.confirmationConfiguration = createConfiguration('demandePaiement', 'confirmation');
  },
]);
