'use strict';
angular.module('portailDepotDemandeAide.depot').controller('demandePaiementPreambuleController', [
  '$scope',
  function ($scope) {
    'use strict';

    // Navigation
    $scope.cleanNavigate();
    $scope.navigate.ns = $scope.preambuleConfiguration.ns;
    $scope.navigate.noform = true;

    $scope.stepsWizard.steps = $scope.getDemandePaiementSteps();
    $scope.stepsWizard.active = 'preambule';

    $scope.navigate.next = function (forget) {
      $scope.goToStep('informations-generales', forget);
    };

    // Page Options
    $scope.pageOptions = _.get($scope.teleserviceConfiguration, 'workflow.pagePreambule', {});
    if (!$scope.pageOptions.actif) {
      $scope.navigate.next(true);
    } else {
      $scope.activePage();
    }
  },
]);
