'use strict';
indicateursCtrl.$inject = ['$scope', 'indicateursService', '$timeout', 'IFrameCommunicationManager', '$log', '$q'];
angular.module('portailDepotDemandeAide.depot').controller('depotSimpleIndicateursController', indicateursCtrl);

/* @ngInject */
/**
 *
 * @param {object} $scope
 * @param {object} indicateursService
 * @param {object} $timeout
 * @param {object} IFrameCommunicationManager
 * @param {object} $log
 * @param {object} $q
 */
function indicateursCtrl($scope, indicateursService, $timeout, IFrameCommunicationManager, $log, $q) {
  // Init navigation
  $scope.cleanNavigate();

  let clickedOnNextBtn = false;
  let indicateursCommunicationManager;

  /**
   * Init component
   */
  this.$onInit = () => {
    $scope.navigate.ns = $scope.indicateurs.ns;
    $scope.showErrors = $scope.showErrorsOnNavigate();
    // Recalcul steps
    $scope.stepsWizard.steps = $scope.getSimpleSteps();
    $scope.stepsWizard.active = 'dossier';

    // Check if page indicateur is actif
    const pageIndicateurs = _.get($scope.teleserviceConfiguration, 'workflow.pageIndicateurs', {});
    const isActif = _.get(pageIndicateurs, 'actif', false);
    if (!isActif) {
      $scope.navigate.next(true);
      return;
    }

    const readOnly = false;
    const isContribution = Boolean($scope.contribution);
    const { history = {} } = isContribution ? $scope.contribution : $scope.aide;
    const fetchIndicateurs = indicateursService.shouldFetchIndicateurs(
      history,
      isContribution,
      $scope.aide.indicateursSaisis
    );

    // Check if at least 1 indicateur
    const indicateursSaisisOnDemandeCount = _.get($scope.aide, 'indicateursSaisis', []).length;
    let indicateursCountPromise;
    if (indicateursSaisisOnDemandeCount > 0) {
      indicateursCountPromise = $q.resolve(indicateursSaisisOnDemandeCount);
    } else {
      indicateursCountPromise = fetchIndicateurs ? indicateursService.getIndicateursCount($scope.aide) : $q.resolve(0);
    }

    indicateursCountPromise.then((count) => {
      // check if should automatically go to the next page
      if (count === 0) {
        $scope.navigate.next(true);
      }
    });

    // Init the iframe
    indicateursCommunicationManager = new IFrameCommunicationManager('#indicateursPrevisionnelIframe');
    // Set the iframe url
    $scope.iframeIndicateursPrevisionnelFinancement = indicateursService.getIndicateursIframeUrl(
      $scope.aide,
      readOnly,
      'previewed',
      fetchIndicateurs,
      $scope.contribution
    );
  };

  // Wait for iframe to be loaded
  $timeout(() => {
    iFrameResize(
      {
        heightCalculationMethod: 'lowestElement',
        checkOrigin: false,
        inPageLinks: true,
      },

      '#indicateursPrevisionnelIframe'
    );
  }, 0);

  /**
   * Handle next btn
   *
   * @param {boolean} goToNextPage
   * @returns {void}
   */
  $scope.navigate.next = function (goToNextPage = false) {
    clickedOnNextBtn = true;
    if (goToNextPage) {
      // Go to next page without verification
      $scope.goToStep('document-comptable', true);
    } else if (indicateursCommunicationManager) {
      // Send message to validate indicateurs
      indicateursCommunicationManager
        .sendEvent({
          action: 'validateIndicateursSaisis',
          options: { saveInProgress: _.get($scope, 'teleserviceConfiguration.controleCompletudeDepot', false) },
        })
        .manageEventWithPromise((msg, resolve, reject) => {
          // Listen to iframe to update indicateursSaisis on aide
          indicateursService.updateIndicateursSaisisOnAide(msg, resolve, reject, $scope.aide);
        })
        .then(() => {
          // After saving, go to next page
          // (if the event comes from "next" btn, not "save" btn)
          if (clickedOnNextBtn) {
            $scope.goToStep('document-comptable', false);
          }
        })
        .catch((error) => {
          // iframe validation / user rejected the goToStep
          $log.error('Indicateur iframe failed', error.message);
        })
        .finally(() => {
          clickedOnNextBtn = false;
        });
    }
  };

  // Remove listeners
  $scope.$on('$destroy', () => {
    if (indicateursCommunicationManager) {
      indicateursCommunicationManager.close();
    }
  });
}
