'use strict';
angular.module('portailDepotDemandeAide.depot').controller('demandePaiementIndicateursRealisationController', [
  'indicateursService',
  '$scope',
  '$timeout',
  'IFrameCommunicationManager',
  'aidesService',
  'demandesPaiementService',
  function demandePaiementIndicateursRealisationController(
    indicateursService,
    $scope,
    $timeout,
    IFrameCommunicationManager,
    aidesService,
    demandesPaiementService
  ) {
    $scope.pageOptions = _.get($scope, 'teleserviceConfiguration.workflow.pageIndicateursRealisation', {
      actif: false,
    });

    $scope.cleanNavigate();

    $scope.navigate.ns = $scope.indicateursRealisationConfiguration.ns;

    $scope.stepsWizard.steps = $scope.getDemandePaiementSteps();
    $scope.stepsWizard.active = 'informationsGenerales';

    const iFrameCommunicationManager = new IFrameCommunicationManager('#iframeIndicateursRealisationPaiement');

    /**
     * Next method that must be defined at each stage of the payment request
     * Go to the next step of the payment request
     *
     * @param {boolean} forget
     * @returns {void}
     */
    $scope.navigate.next = function (forget) {
      const nextStep =
        _.get($scope, 'teleserviceConfiguration.workflow.pageDocumentComptable.actif') &&
        demandesPaiementService.getPlanFinancementDepose($scope.demandePaiement)
          ? 'document-comptable'
          : 'domiciliation-bancaire';

      if (forget) {
        $scope.goToStep(nextStep, forget);
      } else if (iFrameCommunicationManager) {
        // Send message to validate indicateurs
        iFrameCommunicationManager
          .sendEvent({
            action: 'validateIndicateursSaisis',
            options: { saveInProgress: _.get($scope, 'teleserviceConfiguration.controleCompletudeDepot', false) },
          })
          .manageEventWithPromise((msg, resolve, reject) => {
            // Listen to iframe to update indicateursSaisis on aide
            indicateursService.updateIndicateursSaisisOnAide(msg, resolve, reject, $scope.aide);
          })
          .then(() => {
            aidesService.saveAide($scope.aide);
            $scope.goToStep(nextStep, false);
          });
      }
    };

    // If the page should not be displayed we go directly to the next one
    if ($scope.displayIndicateursRealisation) {
      $scope.activePage();
    } else {
      $scope.navigate.next(true);
    }

    /**
     * Method init called automatically at the creation of the component
     * Calculate the link of the iframe that will be displayed
     *
     * @returns {void}
     */
    this.$onInit = function () {
      // Get iframe's URL
      $scope.iframeIndicateursRealisationPaiement = indicateursService.getIndicateursIframeUrl(
        $scope.aide,
        false,
        'realized',
        true,
        null,
        $scope.demandePaiement.id
      );
    };

    /**
     * Immediately after the creation of the component and the html rendering reorganize the size of the iframe
     *
     * @returns {void}
     */
    $timeout(function () {
      iFrameResize(
        {
          heightCalculationMethod: 'lowestElement',
          checkOrigin: false,
          inPageLinks: true,
        },

        '#iframeIndicateursRealisationPaiement'
      );
    }, 0);

    /**
     * Listen to the destroy event
     * Close the communication with the iframe
     *
     * @returns {void}
     */
    $scope.$on('$destroy', function () {
      iFrameCommunicationManager.close();
    });
  },
]);
