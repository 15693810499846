'use strict';

angular.module('portailDepotDemandeAide.depot').controller('demandePaiementPiecesController', [
  '$scope',
  'demandesPaiementService',
  'piecesService',
  'StoreService',
  function ($scope, demandesPaiementService, piecesService, StoreService) {
    'use strict';

    // Navigation
    $scope.cleanNavigate();
    $scope.navigate.ns = $scope.piecesConfiguration.ns;
    $scope.navigate.next = $scope.goToStepFn('recapitulatif');

    // Pieces's configuration
    $scope.viewConfiguration = $scope.piecesConfiguration;

    // Steps Wizard
    $scope.stepsWizard.steps = $scope.getDemandePaiementSteps();
    $scope.stepsWizard.active = 'pieces';

    // Page Options
    $scope.pageOptions = $scope.teleserviceConfiguration.workflow.pagePieces;
    if (!$scope.pageOptions.actif) {
      $scope.navigate.next(true);
    } else {
      $scope.activePage();
    }

    /**
     * Pieces initialization
     *
     * @returns {void}
     */
    const initialize = () => {
      // Persistence
      $scope.demandePaiement.pieces = _.concat(
        piecesService.initializePiecesPersistence(
          $scope.pageOptions.modelesPieces,
          $scope.demandePaiement.pieces?.filter(({ fonction }) => fonction === 'depot')
        ),
        $scope.demandePaiement?.teleservicePaiement?.expand?.modelesPieces ?? []
      );

      // Filter the pieces by payment type
      $scope.demandePaiement.pieces =
        $scope.demandePaiement.pieces?.filter(function (piece) {
          const pieceFound = !piece.typesPaiement?.length
            ? true
            : piece.typesPaiement?.find(function (type) {
                const href = type?.expand?._links?.self?.href || type.href;
                return href === $scope.demandePaiement.typePaiement.href;
              });
          return pieceFound;
        }) ?? [];

      // Filter the pieces by famille
      const familleTiers =
        $scope.demandePaiement?.attributaire?.expand?.famille?.href ??
        $scope.demandePaiement?.attributaire?.famille?.href;

      $scope.demandePaiement.pieces = $scope.demandePaiement.pieces?.filter(function (piece) {
        //If the piece doesnt have a familly, we ignore this filter..
        //If not, we filter for the pieces in demandeur.famille
        const pieceFound = !piece.familles?.length
          ? true
          : piece.familles?.find(function (famille) {
              return familleTiers?.includes(famille.href);
            });

        return pieceFound;
      });

      // Shorcut for access to pieces
      $scope.pieces = $scope.demandePaiement.pieces;
    };
    StoreService.demandePaiement.pendingPromises.promise().then(() => initialize());

    $scope.persistenceConfiguration = _.get($scope.teleserviceConfiguration, 'persistenceConfiguration.expand', {});

    // Demande de paiement - Save the demande when we add a new document on piece or update a document
    $scope.saveDemandePaiement = function () {
      demandesPaiementService.saveDemandePaiement($scope.demandePaiement);
    };

    // Url where to upload files
    $scope.urlDocuments = $scope.demandePaiement._links['mgs:documents'].href;

    // Directory of file's icons
    $scope.urlFileIcons = './resources/images/file-icons/';

    /**
     * Evaluate conditionAffichage (visible/hidden) setting on a piece
     *
     * @function isPieceVisible
     * @param {object} piece the piece
     * @returns {boolean} the evaluate condition
     */
    $scope.isPieceVisible = function (piece) {
      return piecesService.evalConditionPiece($scope, piece, 'conditionAffichage', true);
    };

    /**
     * Evaluate conditionObligatoire (required) setting on a piece
     *
     * @function isPieceRequired
     * @param {object} piece the piece
     * @returns {boolean} the evaluate condition
     */
    $scope.isPieceRequired = function (piece) {
      return piecesService.evalConditionPiece($scope, piece, 'conditionObligatoire', true);
    };
  },
]);
