'use strict';
angular.module('portailDepotDemandeAide.tiers').controller('tiersEditController', tiersEditController);

tiersEditController.$inject = [
  '$rootScope',
  '$scope',
  '$q',
  'mdm',
  'tiers',
  'tiersService',
  'viewsService',
  'tiersEditConfiguration',
  'configuration',
  '$state',
  'alertsService',
  '$window',
];

/**
 *
 * @param {object} $rootScope
 * @param {object} $scope
 * @param {object} $q
 * @param {object} mdm
 * @param {object} tiers
 * @param {object} tiersService
 * @param {object} viewsService
 * @param {object} tiersEditConfiguration
 * @param {object} configuration
 * @param {object} $state
 * @param {object} alertsService
 * @param {object} $window
 */
function tiersEditController(
  $rootScope,
  $scope,
  $q,
  mdm,
  tiers,
  tiersService,
  viewsService,
  tiersEditConfiguration,
  configuration,
  $state,
  alertsService,
  $window
) {
  // Mdm
  $scope.mdm = mdm;

  // Configurations of the page
  $scope.tiersEditConfiguration = tiersEditConfiguration;

  // Directory of file's icons
  $scope.urlFileIcons = './resources/images/file-icons/';

  // Url for retrieve list of documents
  $scope.urlDocuments = configuration.documentCollect.url + '/root/tiers/' + tiers.reference;

  // Tiers
  $scope.tiers = tiers;

  // the porte-documents can have been updated, thus we have to check
  const updatedPieces = tiersService.mergePiecesFamilleInTiers(tiers);
  updatedPieces.then((pieces) => {
    $scope.tiers.pieces = pieces;
  });

  $scope.isAssociation = _.get(tiers, 'famille.expand.typeFamille') === 'TIERS_ASSOCIATION';

  var isTiersPhysique = _.get(tiers, 'famille.expand.personnaliteJuridique') === 'PHYSIQUE';

  var ficheRecapitulativeReady = $q.defer();
  var ressourceshumainesReady = $q.defer();
  var agrementstiersassociationReady = $q.defer();
  var unionsfederationsreseauxtiersassociationReady = $q.defer();

  // The title and the breadcrumb are set accordingly to the tiers nature
  $state.$current.data = $state.$current.data || {};
  const currentDataState = $state.$current.data;
  currentDataState.breadcrumb = isTiersPhysique ? 'user.tiers-edit.physic-breadcrumb' : 'user.tiers-edit.breadcrumb';
  currentDataState.title = isTiersPhysique ? 'user.tiers-edit.physic-title' : 'user.tiers-edit.title';

  // Edit mode
  $scope.edit = _.includes(['SUPPORTED', 'ACCEPTABLE'], $scope.tiers.status);
  if ($scope.edit) {
    // functions associated with tiers' famille
    $scope.filteredFonctions = _.filter(mdm.fonctionsrepres.array, function (fonctionLink) {
      return _.find(tiers.famille.expand.fonctionsRepresentants, ['href', fonctionLink.href]);
    });
  }

  // Check if edition is allowed by tenant on EU
  $scope.readOnly = !_.get(configuration, 'tiers.modificationIdentificationTiersParComptes', false);

  if ($scope.readOnly) {
    // If not, display a message asking users to contact their agent
    $scope.tiersEditAlerts = alertsService.getAlertInfo('tiers.warning.modify.content');
  }

  // Can the current user invite other users?
  $scope.administrator = tiers.linkedUsers?.some(
    // Check if the current user if administrator of the account
    ({ form, href }) => form === 'ADMINISTRATOR' && href === $scope.currentUser.self
  );

  /**
   * Iframe to referentiel-tiers
   *
   * @param {string} reference
   * @param {string} page
   * @returns {string}
   */
  function getIframeReferentielTiersUrl(reference, page) {
    var url = tiersService.getIframeUrl(reference, page, { readOnly: $scope.readOnly, richtext: false });
    return url;
  }

  /**
   * @returns {Promise}
   */
  function loadFicheRecapitulative() {
    $scope.ficheRecapitulativeUrl = getIframeReferentielTiersUrl($scope.tiers.reference, 'ficherecapitulative');
    return ficheRecapitulativeReady.promise;
  }

  /**
   * @returns {Promise}
   */
  function loadResourcesHumaines() {
    $scope.ressourcesHumainesUrl = getIframeReferentielTiersUrl($scope.tiers.reference, 'ressourceshumaines');
    return ressourceshumainesReady.promise;
  }

  /**
   * @returns {Promise}
   */
  function loadAgrementsTiersAssociation() {
    $scope.agrementsUrl = getIframeReferentielTiersUrl($scope.tiers.reference, 'agrementstiersassociation');
    return agrementstiersassociationReady.promise;
  }

  /**
   * @returns {Promise}
   */
  function loadUnionsFederationsReseauxTiersAssociation() {
    $scope.unionsFederationsReseauxUrl = getIframeReferentielTiersUrl(
      $scope.tiers.reference,
      'unionsfederationsreseauxtiersassociation'
    );

    return unionsfederationsreseauxtiersassociationReady.promise;
  }

  /**
   *
   */
  function updateInfosTiersContext() {
    const viewsIframe = angular.element('#viewsInfosComps');
    if (viewsIframe && viewsIframe[0]) {
      viewsIframe[0].contentWindow.postMessage(
        {
          action: 'updateContextExpressions',
          contextExpressions: JSON.parse(JSON.stringify($scope.tiers)),
        },

        '*'
      );
    }
  }

  /**
   * This function allows to set the viewsIframeSrc proprety used to load data-schemas iframe for tiers
   * According the optin we load the iframe with tiers views only or merged with famille views
   *
   * @returns {void}
   */
  function loadDataSchemasIframeForTiers() {
    const familleHref = $scope.tiers?.famille?.href;

    const displaySaveButton = !$scope.readOnly;

    // Get famille views and merge it with tiers views
    (displaySaveButton ? tiersService.getFamille(familleHref, ['views.schema']) : $q.resolve()).then(
      (familleConfig) => {
        // If the iframe is in editable mode, we merge tiers views with famille views
        // If not, we keep tiers views only
        if (displaySaveButton) {
          $scope.tiers.views = viewsService.mergeTiersViewsWithFamilleViews(
            familleConfig.views ?? [],
            $scope.tiers.views ?? []
          );
        }

        $scope.actifViews = tiersService.hasActifViews($scope.tiers);
        $scope.viewsIframeSrc = tiersService.getViewsIframeSrcFromTiers(
          $scope.tiers,
          $scope.readOnly,
          displaySaveButton
        );

        setIframeResizer('#viewsInfosComps');
      }
    );
  }

  /**
   * @returns {Promise}
   */
  function loadAllIframes() {
    return loadFicheRecapitulative()
      .then(() => loadDataSchemasIframeForTiers())
      .then(() => {
        if ($scope.isAssociation) {
          // Additional pages for tiers association
          return loadAgrementsTiersAssociation()
            .then(loadUnionsFederationsReseauxTiersAssociation)
            .then(loadResourcesHumaines);
        }
      });
  }

  /**
   *
   * @param {string} id
   */
  function setIframeResizer(id) {
    iFrameResize(
      {
        heightCalculationMethod: 'taggedElement',
        checkOrigin: false,
        inPageLinks: true,
      },

      id || '.referentiel-tiers-iframe'
    );
  }

  // Display page content only when the first iframe is loaded
  /**
   *
   * @param {object} msg
   */
  function iframeListener(msg) {
    const action = _.get(msg, 'data.action');
    const source = _.get(msg, 'data.source');
    if (action === 'ready') {
      const route = _.get(msg, 'data.route');
      if (route === 'ficheRecapitulative') {
        $scope.$apply(function () {
          $scope.ficheRecapitulativeLoaded = true;
          ficheRecapitulativeReady.resolve();
          setIframeResizer();
        });
      } else if (route === 'ressourceshumaines') {
        ressourceshumainesReady.resolve();
      } else if (route === 'agrementstiersassociation') {
        agrementstiersassociationReady.resolve();
      } else if (route === 'unionsfederationsreseauxtiersassociation') {
        unionsfederationsreseauxtiersassociationReady.resolve();
      } else {
        // updated tiers context when ready, else hidding condition may not work properly
        updateInfosTiersContext();
      }
    } else if (action === 'savedTiers') {
      const tiers = msg.data?.tiers;
      if (tiers) $scope.tiers = tiers;

      $rootScope.$broadcast('update-tiers');
      updateInfosTiersContext();
    }

    // we resize data-schema after ready event and modal update
    if ((action === 'modal.hide' || action === 'ready') && source === 'data-schemas.views') {
      setIframeResizer('#viewsInfosComps');
    }
  }

  /**
   * Save tiers
   *
   * @param {object} tiers
   */
  $scope.saveTiers = function (tiers) {
    // If we don't pass the tiers in parameter of the function, we use the tiers on the scope instead
    if (!tiers) {
      tiers = $scope.tiers;
    }

    tiersService.saveTiers(tiers, mdm);
  };

  loadAllIframes();

  $window.addEventListener('message', iframeListener, false);

  $scope.$on('$destroy', () => {
    $window.removeEventListener('message', iframeListener, false);
  });
}
