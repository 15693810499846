'use strict';
/**
 * @param {object} $scope
 * @param {object} $state
 * @param {object} teleservicesService
 * @param {object} teleserviceConfiguration
 * @param {object} mdm
 * @param {object} masterdata
 * @param {object} tiers
 * @param {object} configurationId
 * @module portailDepotDemandeAide
 * @name depotController
 */
angular.module('portailDepotDemandeAide.depot').controller('depotController', [
  '$scope',
  '$state',
  'teleservicesService',
  'teleserviceConfiguration',
  'mdm',
  'masterdata',
  'tiers',
  'configurationId',
  function ($scope, $state, teleservicesService, teleserviceConfiguration, mdm, masterdata, tiers, configurationId) {
    $scope.mdm = mdm;
    $scope.masterdata = masterdata;
    $scope.configurationId = configurationId;

    $scope.tiers = tiers;

    // Retrieve teleservice's configuration
    $scope.teleserviceConfiguration = teleserviceConfiguration;

    // Prepare structure for stepsWizard, will be completed by each page controllers
    $scope.stepsWizard = {
      steps: [],
      active: null,
      labelsPrefix: 'teleservice.steps',
    };

    // Prepare objects to be filled with navigation functions by children controllers
    $scope.navigate = {
      next: null,
      validate: null,
      finish: null,
      noform: false,
      ns: null,
      lock: 0,
      block: false,
      beforePrevious: null,
      afterSave: null,
    };

    // Allow pages to manually block next and previous buttons
    $scope.navigate.lockNavigation = function () {
      $scope.navigate.lock++;
    };
    $scope.navigate.unlockNavigation = function () {
      $scope.navigate.lock = Math.max(0, $scope.navigate.lock - 1);
    };

    // Reinit the navigate parameters, used when going to a new step in
    // order to prevent mixing logic accross individual pages controllers
    $scope.cleanNavigate = function () {
      $scope.navigate.next = null;
      $scope.navigate.validate = null;
      $scope.navigate.finish = null;
      $scope.navigate.noform = false;
      $scope.navigate.ns = null;
      $scope.navigate.lock = 0;
      $scope.navigate.block = false;
      $scope.navigate.beforePrevious = null;
      $scope.navigate.afterSave = null;
    };

    // Loads the title of the page each time it is changed
    $scope.$watch('navigate.ns', function () {
      $scope.pageTitle = $scope.navigate.ns + '.legend';
    });

    $scope.$on('changeTitleTransmissionAttestation', function (event, data) {
      $scope.pageTitle = data.title;
    });

    // Prevent user from using Next and Previous while a modal is open
    $scope.$on('modal.show', $scope.navigate.lockNavigation);
    $scope.$on('modal.hide', $scope.navigate.unlockNavigation);

    // Prevent user from using Next and Previous when 412 conflict
    $scope.$on('depot.conflict', $scope.navigate.lockNavigation);
  },
]);
