'use strict';
/**
 * @module portailDepotDemandeAide
 * @name personalDatasController
 * @description This controller manage personal-datas.html
 */
angular.module('portailDepotDemandeAide').controller('personalDatasController', [
  '$scope',
  '$translate',
  '$window',
  '$cookies',
  'configuration',
  'matomoService',
  '$document',
  /**
   *
   * @param {object} $scope
   * @param {object} $translate
   * @param {object} $window
   * @param {object} $cookies
   * @param {object} configuration
   * @param {object} matomoService
   * @param {object} $document
   * @returns {void}
   */
  function ($scope, $translate, $window, $cookies, configuration, matomoService, $document) {
    // Init tracking status

    $scope.matomoStatus = configuration.analytics.matomo?.active;
    $scope.doNotTrackStatus = $window.navigator.doNotTrack === '1';
    $scope.consentStatus = !$cookies.get('mtm_consent_removed') ?? true;

    // Init the htmlEditorText above and below the consent tag

    const consentTag = '{{consentement}}';
    const htmlEditorText = $translate.instant('donnees-personnelles.htmlEditorText', { consentement: consentTag });
    const consentTagStartIndex = htmlEditorText.indexOf(consentTag);

    $scope.upperHtmlEditorText = htmlEditorText.substr(0, consentTagStartIndex) ?? '';
    $scope.lowerHtmlEditorText = htmlEditorText.substr(consentTagStartIndex + consentTag.length) ?? '';

    // Init RGPD content text

    $scope.content = $translate.instant('donnees-personnelles.content');

    // Init consentStatusText

    const optInTabTextarea = $translate.instant('donnees-personnelles.optInTabTextarea');
    const optOutTabTextarea = $translate.instant('donnees-personnelles.optOutTabTextarea');
    const blockedByBrowserTabTextarea = $translate.instant('donnees-personnelles.blockedByBrowserTabTextarea');

    /**
     * Return consent status text based on consent status
     *
     * @returns {string} consent status
     */
    const getConsentStatusText = () => {
      if ($scope.doNotTrackStatus) {
        return blockedByBrowserTabTextarea;
      }
      return $scope.consentStatus ? optInTabTextarea : optOutTabTextarea;
    };

    $scope.consentStatusText = getConsentStatusText();

    // Init checkbox

    const optInTabCheckboxTitle = $translate.instant('donnees-personnelles.optInTabCheckboxTitle');
    const optOutTabCheckboxTitle = $translate.instant('donnees-personnelles.optOutTabCheckboxTitle');

    // Needed to decode the html entities after sanitization
    /**
     * Convert the html entities inside a string
     *
     * @param {string} string string to decode
     * @returns {string} decoded string
     */
    const decodeHTMLEntities = (string) => {
      let textArea = $document[0].createElement('textarea');
      textArea.innerHTML = string;
      return textArea.value;
    };

    $scope.checkboxValue = $scope.consentStatus
      ? [{ title: decodeHTMLEntities(optInTabCheckboxTitle), value: true }]
      : [{ title: decodeHTMLEntities(optOutTabCheckboxTitle), value: false }];

    /**
     * Opt-in/out user on consent status change
     *
     * @param {object[]} event checkbox event
     * @returns {void}
     */
    $scope.handleConsentStatusChange = (event) => {
      const checkbox = event[0];

      if ($scope.consentStatus !== checkbox.value) {
        $scope.consentStatus = checkbox.value;

        if (!$scope.consentStatus) {
          matomoService.optUserOut();
        } else {
          matomoService.forgetUserOptOut();
        }

        // For accessibility purpose. To avoid a hot refresh of the element above, we can't use $state.reload()
        location.reload();
      }
    };
  },
]);
