import { asDatetime } from '@mgdis/date-utils';

/**
 * Returns a function that formats a datetime value according to the specified style and locale.
 *
 * @returns {Function} A function that takes a date value as its first argument, and an optional style string as its second argument. The function returns a formatted date string.
 * @param {object} configuration configuration application object
 */
function asDatetimeFilter(configuration) {
  return (dateValue, options) => {
    const mergedOptions = { locale: 'fr', timeZone: configuration.timeZone, ...options };
    return asDatetime(dateValue, mergedOptions);
  };
}

asDatetimeFilter.$inject = ['configuration'];

export { asDatetimeFilter };
