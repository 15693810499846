'use strict';
/**
 * @param {object} Geographique
 * @param {object} validationService
 * @param {object} Coordonnees
 * @param {object} $translate
 * @example
 * <representant-form
      representant="representant"
      edit="edit"
      user="user"
      telephonesTiers="telephones"
      situation-tiers="situationTiers"
      view-configuration="viewConfiguration"
      adresse-inconnue-active="false"
      mdm="mdm"
      section-adresse-contacts="sectionAdresseContacts"
      is-accessed-through-sharing="isAccessedThroughSharing">
  </representant-form>
""""""""""""""
 * @returns {object}
 */
angular.module('tiers').directive('representantForm', [
  'Geographique',
  'validationService',
  'Coordonnees',
  '$translate',

  function (Geographique, validationService, Coordonnees, $translate) {
    'use strict';

    return {
      replace: true,
      transclude: true,
      templateUrl: 'tiers/tiers-directives/representant-form/representant-form.html',
      scope: {
        representant: '=',
        situationTiers: '=',
        user: '=',
        telephonesTiers: '=',
        edit: '=',
        viewConfiguration: '=',
        adresseInconnueActive: '=',
        mdm: '=',
        fonctions: '=',
        sectionAdresseContacts: '<',
        isAccessedThroughSharing: '<',
      },

      link: function (scope) {
        scope.civilites = scope.mdm.civilites.array;
        scope.$watch('fonctions', function () {
          scope.listeFonctions = scope.fonctions || _.get(scope.mdm, 'fonctionsrepres.array') || [];
        });
        // If the address of the representative is not different from the address of the structure (tiers),
        // the address of the structure is directly assigned to the representative.
        // Unless the option 'Affichage de la section adresse des contacts' is disabled (admin-portal)
        if (
          _.get(scope, 'sectionAdresseContacts.active') === true &&
          _.get(scope, 'representant.adresseCommuneTiers') === true
        ) {
          // Automatic creation of an address if the tiers doesn't have
          if (_.isEmpty(scope.representant.situations)) {
            scope.representant.situations.push(new Coordonnees());
          }

          var situation = scope.representant.situations[0];
          situation.geographique = new Geographique(angular.copy(scope.situationTiers.geographique), scope.mdm);
          situation.afnor = angular.copy(scope.situationTiers.afnor);
          situation.etrangere = situation.geographique.isForeignAddress();
        }

        // Manage phones
        var getPhone = function (phones, type) {
          // Find phone by type
          var index = _.findIndex(phones, function (phone) {
            return phone.TYPE === type;
          });
          if (index > -1) {
            return phones[index].value;
          } else {
            // Backward compatibility, may be there is no type so 0 = home, 1 = cell and 2 = fax
            var position = type === 'home' ? 0 : type === 'cell' ? 1 : type === 'fax' ? 2 : -1;
            if (typeof phones[position] !== 'undefined' && !phones[position].TYPE) {
              return phones[position].value;
            } else {
              return '';
            }
          }
        };

        // Allow to control representant fonction value and delete if it's not valued
        scope.onFonctionChange = function () {
          if (_.isEmpty(scope.representant.fonction)) {
            delete scope.representant.fonction;
          }
        };

        // Allow to control representant civilite value and delete if it's not valued
        scope.onCiviliteChange = function () {
          if (_.isEmpty(scope.representant.civilite)) {
            delete scope.representant.civilite;
          }
        };

        scope.phone = {};
        scope.phone.home = scope?.representant?.telephones && getPhone(scope.representant.telephones, 'home');
        scope.phone.cell = scope?.representant?.telephones && getPhone(scope.representant.telephones, 'cell');
        scope.phone.fax = scope?.representant?.telephones && getPhone(scope.representant.telephones, 'fax');

        /**
         * Fill current representant with user data
         *
         * @returns {void}
         */
        function initRepresentant() {
          if (!scope.isAccessedThroughSharing) {
            scope.representant.fillRepresentant(
              scope.user,
              scope.telephonesTiers,
              scope.mdm,
              scope.sectionAdresseContacts.active
            );
          }
          scope.phone = {};
          scope.phone.home = _.get(scope, 'representant.telephones') && getPhone(scope.representant.telephones, 'home');
          scope.phone.cell = _.get(scope, 'representant.telephones') && getPhone(scope.representant.telephones, 'cell');
          scope.phone.fax = _.get(scope, 'representant.telephones') && getPhone(scope.representant.telephones, 'fax');
        }

        //Init representant on load
        if (scope.user && scope.representant.userIsRepresentant === true) {
          initRepresentant();
        }

        scope.$watchGroup(['phone.home', 'phone.cell', 'phone.fax'], function (newVal) {
          if (scope.representant) {
            scope.representant.telephones = newVal
              .map(function (phone, index) {
                return {
                  TYPE: index === 0 ? 'home' : index === 1 ? 'cell' : 'fax',
                  value: phone,
                };
              })
              .filter(function (phone) {
                return !_.isEmpty(phone.value);
              });
          }
        });

        scope.$watch('representant.civilite', function () {
          // The default value of civilite (an empty MasterDataRecord) is replaced by undefined in order to ensure that this field is filled
          if (!_.get(scope.representant, 'civilite.href')) {
            _.set(scope.representant, 'civilite', undefined);
          }
        });

        // Toggle user is representant
        scope.$watch('representant.userIsRepresentant', function (newVal, oldVal) {
          if (scope.user && newVal !== oldVal) {
            initRepresentant();
          }
        });

        // Civilite object is initialized with a non empty object
        // https://jira.mgdis.fr/browse/PLAID-3485
        if (!_.get(scope.representant, 'civilite.href')) {
          _.set(scope.representant, 'civilite', undefined);
        }

        /**
         * Check if email respect RFCs
         */
        scope.validationService = validationService;

        scope.truncate = function (name, sizeLimit) {
          if (name) {
            return name.substring(0, sizeLimit);
          }
          return name;
        };

        /**
         * adresseCommuneTiers is set with merged ressource.
         * It can be default or customed
         */
        scope.adresseCommuneTiersLabel = $translate.instant(
          _.get(scope, 'viewConfiguration.ns') + '.adresseCommuneTiers'
        );

        // For the form "Beneficiaire Representant Legal", the label is NOT adresseCommuneTiers but adresseDiffTiers.label
        const benefRepLegal = 'teleservice.beneficiaire-representant-legal';
        if (_.get(scope, 'viewConfiguration.ns') === benefRepLegal) {
          // check if custom label exists
          const customLabelPath = benefRepLegal + '.adresseDiffTiers.label';
          const customLabel = $translate.instant(customLabelPath);
          // If translation was found we set the label (if not found the instant method returns the path)
          if (customLabel !== customLabelPath) {
            scope.adresseCommuneTiersLabel = customLabel;
          }
        }
      },
    };
  },
]);
