'use strict';
angular.module('portailDepotDemandeAide').controller('connectedController', [
  '$scope',
  '$state',
  '$timeout',
  '$rootScope',
  '$stateParams',
  '$modal',
  '$transitions',
  'accountPublicSettings',
  'agreementService',
  'StoreService',
  'tiersService',
  'routeUtilsService',
  'IFrameCommunicationManager',
  'selectableTiers',
  'selectedTiers',
  'famillesCount',
  '$document',
  '$window',
  '$translate',
  function (
    $scope,
    $state,
    $timeout,
    $rootScope,
    $stateParams,
    $modal,
    $transitions,
    accountPublicSettings,
    agreementService,
    StoreService,
    tiersService,
    routeUtilsService,
    IFrameCommunicationManager,
    selectableTiers,
    selectedTiers,
    famillesCount,
    $document,
    $window,
    $translate
  ) {
    $scope.tenantId = $stateParams.tenantId;
    $scope.accountHref = $state.href('app.connected.user.account-user');
    $scope.print = $stateParams.print === 'true' || null;
    $scope.selectableTiers = selectableTiers;
    $scope.selectedTiers = selectedTiers;
    $scope.currentTiersRef = selectedTiers?.reference;
    $scope.famillesCount = famillesCount;

    let iframeResizerList = [];
    const iFrameCommunicationManager = new IFrameCommunicationManager();

    const fullWidthPages = ['app.connected.dashboard.accueil'];

    // hide some ui elements on special connected screens
    $scope.$watch('$state.current.name', function () {
      $scope.hideLayout = $state.current.name === 'app.connected.tiers-selection';
      $scope.fullWidthPage = fullWidthPages.includes($state.current.name);

      // Handle skip links
      $scope.skipLinks = [
        {
          href: '#content',
          label: $translate.instant('skip-links.content'),
          display: true,
        },
        {
          href: '#menu',
          label: $translate.instant('skip-links.menu'),
          display: $state.current.name !== 'app.connected.tiers-selection',
        },
        {
          href: '#footer',
          label: $translate.instant('skip-links.footer'),
          display: true,
        },
      ]
        .filter(({ display }) => display)
        .map(({ label, href }) => ({ label, href }));
    });

    // We use a transition hook to make our dashboard disappear on accueil reload
    // for example, on changing tiers
    // The only drawback is that we'll eventually have to unregister it on destroy.
    // As we already have some events to 'clean' this is acceptable.
    const transCriteria = {
      to: 'app.connected.dashboard.accueil',
      from: (state) => state.name !== 'app.connected.tiers-selection',
    };

    const unregisterLoadingTransition = $transitions.onStart(transCriteria, function (transition) {
      $scope.stateLoading = true;
      transition.promise.finally(() => {
        $scope.stateLoading = false;
      });
    });

    // Init the listener for the iframe
    iFrameCommunicationManager.manageEvent(({ data }) => {
      const { route, action, value } = data;

      if (action === 'ready') {
        // used to resize opened iframe
        $timeout(() => resizeIframe());
      } else {
        switch (route) {
          case 'demandes-financement-view.ux':
          case 'home-view.ux':
            switch (action) {
              case 'update-page': // update page in url
                $state.go('app.connected.dashboard.aides.demandesAides.aides', { page: value?.toString() });
                break;
              case 'update-page-shared':
                $state.go('app.connected.dashboard.aides.demandesAides.sharedAides', { page: value?.toString() });
                break;
              case 'recapitulatif':
                $state.go('app.connected.dashboard.recapitulatif', {
                  aide: value?.toString(),
                  '#': 'tcRecapDecisions',
                });
                break;
              default: // when demande financement action is clicked
                routeUtilsService.goTo(action, value, $scope);
            }
            break;
          case 'sollicitations-view.ux':
            switch (action) {
              case 'action-clicked': // when sollicitation action is clicked
                routeUtilsService.handleSollicitationActions(value, $scope.$new());
                break;
            }
            break;
        }
      }
    });

    /**
     * this get the current iframe and resize it
     *
     * @returns {void}
     */
    function handleCurrentIframe() {
      // get iframes
      const iframes = $document[0]?.getElementsByTagName('iframe');

      iframes.forEach((iframe) => {
        if (!iframe?.iFrameResizer && iframe.id) {
          const id = `#${iframe.id}`;
          const resizer = iFrameResize(
            {
              heightCalculationMethod: 'taggedElement',
              checkOrigin: false,
              inPageLinks: true,
              // scrolling: 'omit', // TODO: Enable this option when https://github.com/davidjbradshaw/iframe-resizer/issues/1142 will be fixed
            },
            id
          );
          iframeResizerList.push(resizer?.[0]?.iFrameResizer);
        }
      });
    }

    /**
     * Resize iframe
     *
     * @returns {void}
     */
    function resizeIframe() {
      iframeResizerList.forEach((iframeResizer) => {
        iframeResizer.resize();
      });
    }

    /**
     * close iframeResizer
     *
     * @returns {void}
     */
    function closeIframeResizer() {
      iframeResizerList.forEach((iframeResizer) => iframeResizer.close());
      iframeResizerList = [];
    }

    $rootScope.$on('$locationChangeSuccess', () => {
      $timeout(() => {
        handleCurrentIframe();
      });
    });
    $rootScope.$on('$includeContentLoaded', () => {
      $timeout(() => {
        handleCurrentIframe();
      });
    });
    $rootScope.$on('$viewContentLoaded', () => {
      closeIframeResizer();
      $timeout(() => {
        handleCurrentIframe();
      });
    });

    /**
     * change tiers request listener
     *
     * @param {object} msg iframe message
     * @returns {void}
     */
    function changeTiersRequestCallback(msg) {
      const action = _.get(msg, 'data.action');
      const source = _.get(msg, 'data.source');

      // Change current Tiers restricted to tiers-selection screen for now
      if (
        source === 'referentiel-tiers' &&
        action === 'changeTiers' &&
        _.get($state, 'current.name') === 'app.connected.tiers-selection'
      ) {
        const tiersRef = _.get(msg, 'data.reference');
        // On iframe event, we set our new reference in localstorage
        // This will trigger the storage event which will reload other opened window or tab.
        if (!$scope.currentTiersRef || tiersRef !== $scope.currentTiersRef) {
          if (_.get($state, 'params.redirectFrom') === 'portal') {
            // those params are dynamic. We must ensure that those are reinitialized.
            // to avoid non wanted redirection when manually changing tiers.
            $state.params.redirectTo = 'app.connected.dashboard.accueil';
            $state.params.redirectParams = JSON.stringify({});
          }
          StoreService.currentTiersRef.set(tiersRef, true);
          routeUtilsService.handleRedirectionOrGoTo('app.connected.dashboard.accueil', { reload: true });
        } else if ($scope.currentTiersRef === tiersRef) {
          // tiers hasn't changed.so we want to reset the state to where we were.
          // without reloading the state.
          // We will directly use redirectTo and redirectFrom params and
          // need to handle badly formatted parameters.
          let previousLocation = _.get($state, 'params.redirectTo') || 'app.connected.dashboard.accueil';
          let previousParams = {};
          const rawpreviousParams = _.get($state, 'params.redirectParams');
          if (rawpreviousParams) {
            try {
              previousParams = JSON.parse(rawpreviousParams);
            } catch (err) {
              previousLocation = 'app.connected.dashboard.accueil';
            }
          }
          $state.go(previousLocation, previousParams);
        }
      }
    }
    // register the listener.
    iFrameCommunicationManager.manageEvent(changeTiersRequestCallback);

    /**
     * Local storage listener
     * used to reload on current tiers changes from other windows/tabs. (ls.current-tiers-ref)
     *
     * @param {object} storageEvent event
     * @returns {void}
     */
    function tiersChangedInStorageCallback(storageEvent) {
      if (storageEvent.key === 'ls.current-tiers-ref') {
        // first ensure that our cached current tiers is unset
        // Obviously, we don't want to unset it in localstorage.
        StoreService.currentTiersRef.unset(false);
        // Reloading our state with the newly instorage set tiers
        $state.go('app.connected.dashboard.accueil', {}, { reload: true });
      }
    }
    // register the listener.
    $window.addEventListener('storage', tiersChangedInStorageCallback);

    /**
     * Fonction d'ouverture des modals paramétrées
     *
     * @param {object} modals
     */
    const openModal = function (modals) {
      if (modals.length > 0) {
        // Blocage de la navigation pour ne pas outrepasser les modals obligatoires
        $rootScope.blockedNavigation = true;

        const scopeModal = $scope.$new();
        scopeModal.data = _.get(_.first(modals), 'data');

        // Cette fonction devra être appelé à la fermeture de la modal "courante", et...
        scopeModal.next = function () {
          // ...à sa fermeture, on rappelle la fonction en retirant la modal "courante" du tableau pour ouvrir la suivante
          openModal(_.slice(modals, 1));
        };

        $modal({
          template: _.get(_.first(modals), 'template'),
          backdrop: 'static',
          keyboard: false,
          scope: scopeModal,
        });
      } else {
        // une fois que toutes les modals "obligatoires" sont fermées, on débloque la navigation
        $rootScope.blockedNavigation = false;
      }
    };

    // Lastly we listen for current tiers change to update mainbar tiers button
    $scope.$on('tiers.current.updated', () => {
      tiersService.getAllCurrentUserTiers(true).then((allTiers) => {
        $scope.selectableTiers = allTiers;
      });
      tiersService.getCurrentTiers().then((currentTiers) => {
        $scope.selectedTiers = currentTiers;
      });
    });

    /**
     * le tableau de modal permet d'enchaîner des modals :
     *  - "scope" permettant de transmettre des infos dans le scope de la modal
     *  - "next" ouvre la modal 'enfant' et est appelée à la fermeture de la modal 'parente'
     */
    var modalTab = [];

    // 1 - Affichage modal CGU
    var cguModal = {
      template: 'modal/cgu.html',
      controller: 'cguController',
      data: {
        publicSettings: accountPublicSettings,
      },
    };

    var datePublication = _.get(accountPublicSettings, 'cgu.datePublication');
    var dateAcceptationCGU = _.get($rootScope, 'currentUser.dateAcceptationCGU');
    if (
      datePublication &&
      (_.isNil(dateAcceptationCGU) || (!_.isNil(dateAcceptationCGU) && dateAcceptationCGU < datePublication))
    ) {
      modalTab.push(cguModal);
    }

    // 2 - Affichage modal "Abonnements et autorisations"
    var agreementModal = {
      template: 'modal/agreement.html',
      controller: 'agreementController',
    };

    // Condition d'affichage des 'agreements'
    // il faut au moins un 'agreement' actif non encore validé par le user
    agreementService.getList($scope.tenantId).then(function (list) {
      // Récupération des agreements 'actif' ET date d'activation antérieure ou égale à la date du jour
      var listeAgreement = _.filter(list, function (agreement) {
        return agreement.actif && moment(agreement.dateActivation).isSameOrBefore(new Date(), 'day');
      });

      // Récupération des agreements du user ayant une date de validation
      var agreementsUser = _.get($rootScope, 'currentUser.agreements');
      var validateAgreementsUser = _.filter(agreementsUser, function (agreement) {
        return agreement.dateValidation;
      });

      // Exclusion des agreements déjà validés par le user
      if (!_.isNil(validateAgreementsUser)) {
        listeAgreement = _.filter(listeAgreement, function (agreement) {
          return !_.find(validateAgreementsUser, function (userAgreement) {
            return userAgreement.id === agreement.id && userAgreement.dateValidation;
          });
        });
      }

      if (listeAgreement && listeAgreement.length > 0) {
        modalTab.push(agreementModal);
      }

      // Ouvre la première modal du tableau
      openModal(modalTab);
    });

    // on destroy, we always ensure that listeners
    // are removed.
    $scope.$on('$destroy', function () {
      unregisterLoadingTransition();
      iFrameCommunicationManager.close();
      $window.removeEventListener('storage', tiersChangedInStorageCallback);
    });
  },
]);
