'use strict';
angular.module('portailDepotDemandeAide.depot').controller('depotSimplePiecesController', [
  '$q',
  '$scope',
  'aidesService',
  'cmisService',
  'piecesService',
  'StoreService',
  'contributionsService',
  'Piece',
  'contributionsConstants',
  '$log',
  function (
    $q,
    $scope,
    aidesService,
    cmisService,
    piecesService,
    StoreService,
    contributionsService,
    Piece,
    contributionsConstants,
    $log
  ) {
    'use strict';

    $scope.loaded = false;
    $scope.pageOptions =
      // First try to get the teleservice at the right revision
      $scope.aide.teleservice.expand?.workflow?.simple?.pagePieces ??
      $scope.teleserviceConfiguration.workflow.pagePieces;
    $scope.cleanNavigate();
    $scope.navigate.ns = $scope.piecesConfiguration.ns;
    $scope.piecesConfiguration.showErrors = $scope.showErrorsOnNavigate();

    $scope.stepsWizard.steps = $scope.getSimpleSteps();
    $scope.stepsWizard.active = 'dossier';

    $scope.pieceUploadInProgress = false;

    let mapIsPieceVisible;

    $scope.navigate.next = function (forget) {
      $scope.goToStep('recapitulatif', forget);
    };

    // If the page is hidden directly navigate to next page and forget this one in the history
    if (!$scope.pageOptions.actif) {
      $scope.navigate.next(true);
    }

    // Update piece visibility after saveAide has ended
    // Because aide.specifiques is sync with aide.views during PUT
    // For backward compatibility reasons
    StoreService.depot.pendingPromises
      .promise()
      .then((promises) => {
        //? Every time we save the aide, the result is saved in promises
        //? => the last promises items is the latest version of the aide
        const updatedPieces = Array.isArray(promises) && promises.length > 0 && promises[promises.length - 1].pieces;
        // if updatedPieces.length === 0, it means that the pieces are being initialized by the screen don't overwrite them
        if (updatedPieces.length > 0) {
          $scope.aide.pieces = updatedPieces;
        }
      })
      .then(() => {
        return $q.all(
          // expand documents (porte-documents needs them)
          cmisService.expandAllPiecesDocuments($scope.tiers?.pieces)
        );
      })
      .then(() => init())
      .catch((error) => {
        $log.error('(depot-simple-piece-controller) init error', error);
      });

    /**
     *
     */
    function init() {
      $scope.kind = $scope.contribution ? 'contribution' : 'aide';
      $scope.entity = $scope.contribution || $scope.aide;

      const piecesToAdd = piecesService.piecesNotInDemandeButInTS($scope.pageOptions.modelesPieces, $scope.aide.pieces);

      // Set envoiPostal from TS configuration
      $scope.aide.pieces.forEach((piece) => {
        if (piece.fonction === 'depot') {
          const pieceFromTS = $scope.pageOptions.modelesPieces.find(
            (modelPiece) => modelPiece.fonction === 'depot' && modelPiece.reference === piece.reference
          );
          piece.envoiPostal = pieceFromTS?.envoiPostal || false;
        }
      });

      if (piecesToAdd?.length > 0) {
        piecesToAdd.forEach((pieceReference) => {
          const pieceFromTS = $scope.pageOptions.modelesPieces.find((piece) => piece.reference === pieceReference);
          const initializedPiece = piecesService.initializePiecePersistence(pieceFromTS, $scope.aide.pieces);
          $scope.aide.pieces.push(initializedPiece);
        });
      }

      $scope.demandeur = StoreService.demandeur.get();

      // Filter the pieces by famille
      const tiersReference = _.get($scope, 'aide.beneficiaires[0].expand') || $scope.demandeur;
      const tiersFamille = _.get(tiersReference, 'famille.href');

      if (tiersFamille) {
        $scope.aide.pieces = filterPiecesByFamille(tiersFamille);
      }

      // Shortcut to access pieces
      // In the case of a contribution we update aide entity (this is the contribution in 'aide' format)
      $scope.pieces = $scope.aide.pieces;

      $scope.persistenceConfiguration = _.get($scope, 'teleserviceConfiguration.persistenceConfiguration.expand') || {};

      // Url where to upload files
      $scope.urlDocuments = $scope.aide._links['mgs:documents'].href;

      // Directory of file's icons
      $scope.urlFileIcons = './resources/images/file-icons/';

      // Pieces's configuration
      $scope.viewConfiguration = $scope.piecesConfiguration;

      mapIsPieceVisible = setMapIsPieceVisible();

      $scope.depenseDocumentComptableTemplate = _.get(
        $scope,
        'teleserviceConfiguration.workflow.pageDocumentComptable.typeDocumentComptable.planFinancement.depense',
        {}
      );

      const currentPlanFinancement = aidesService.getCurrentPlanFinancement($scope.aide);

      $scope.postesWithDocuments = [];
      _.forEach(currentPlanFinancement.depense.postes, (posteCategory) => {
        _.forEach(posteCategory.lignes, (line) => {
          // If the line is created by the user get the category's reference to fetch datas that the line need to be attached.
          const referenceUsedToGetRequiredPieces = line.createdByUser ? posteCategory.reference : line.reference;

          // Get the poste or the category, from the teleservice template.
          const [posteFromTemplate] = JSONPath(
            `$..[?(@.reference == '${referenceUsedToGetRequiredPieces}')]`,
            $scope.depenseDocumentComptableTemplate.postes
          );

          // filter all pieces by tiers family and visibility
          const piecesObligatoires = (posteFromTemplate && posteFromTemplate.piecesObligatoires) || [];
          const piecesFacultatives = (posteFromTemplate && posteFromTemplate.piecesFacultatives) || [];
          const allPieces = _.uniqBy(piecesObligatoires.concat(piecesFacultatives), 'reference');

          const visiblePieces = _.filter(allPieces, isPieceAvailable);

          if (
            ($scope.hasAmount(line) || (posteFromTemplate && posteFromTemplate.obligatoire)) &&
            visiblePieces.length
          ) {
            $scope.postesWithDocuments.push({
              categoryName: posteCategory.libelle.value,
              posteName: line.libelle.value,
              piecesNames: visiblePieces.map((piece) => piece.libelle.value),
            });
          }
        });
      });

      $scope.expensesRelatedDocument = formatPiecesToAssociate($scope.postesWithDocuments);

      // Expenses
      $scope.expenses = {
        enabled: true,
        related: {
          limit: 3,
          items: formatPiecesToAssociate($scope.lignesWithDocuments),
        },
      };

      $scope.loaded = true;
    }

    // Persistence - Save the persistence when we add a new document on piece or update a document
    $scope.savePersistence = function () {
      $scope.aide.pieces = _.uniqBy($scope.aide.pieces, 'reference');
      if ($scope.kind === 'contribution') {
        contributionsService.saveContribution($scope.aide, $scope.contribution);
      } else {
        aidesService.update($scope.aide);
      }
    };

    // Navigation
    $scope.navigate.next = function () {
      $scope.currentlyClickingNextBtn = false;

      if ($scope.pieceUploadInProgress) {
        $scope.currentlyClickingNextBtn = true;
      } else if ($scope.teleserviceConfiguration.controleCompletudeDepot || $scope.completudePiecesLignes()) {
        $scope.errorPiecesLignes.onError = false;
        $scope.goToStep('recapitulatif');
      } else {
        $scope.errorPiecesLignes.onError = true;
      }
    };

    /**
     * check completude of required pieces in planFinancement
     *
     * @returns {boolean} false if there's no documents on a required pieces
     */
    $scope.completudePiecesLignes = function () {
      const posteWithRequiredPieces = JSONPath('$..[?(@.piecesObligatoires)]', $scope.depenseDocumentComptableTemplate);

      const currentPlanFinancement = aidesService.getCurrentPlanFinancement($scope.aide);

      const badCompletude = _.find(posteWithRequiredPieces, (poste) => {
        let pfPostes = [];

        // We have to check completude of required pieces associated to the postes created by the user inside this category.
        if (poste.allowLigneCreation) {
          pfPostes = JSONPath(
            `$.postes[?(@.reference == '${poste.reference}')]..lignes[?(@.createdByUser == true)]`,
            currentPlanFinancement.depense
          );
        } else {
          pfPostes = JSONPath(`$..lignes[?(@.reference == '${poste.reference}')]`, currentPlanFinancement.depense);
        }

        const requiredPiecesVisible = _.filter(poste.piecesObligatoires, (p) => isPieceAvailable(p));

        const error = _.find(pfPostes, (pfPoste) => {
          if ($scope.hasAmount(pfPoste) && requiredPiecesVisible.length) {
            return _.find(requiredPiecesVisible, (piece) => {
              const pfPiece = _.find(pfPoste.pieces, (p) => p.reference === piece.reference);
              // we need this to check if modeTransmission is ENVOIEPOSTAL
              const aidePiece = _.find($scope.pieces, (p) => p.reference === piece.reference);

              // we need this to check if envoiPostal is true
              const tsPiece = _.find(
                _.get($scope, 'pageOptions.modelesPieces', []),
                (p) => piece.reference === p.reference
              );

              return (
                (!pfPiece || _.isEmpty(pfPiece.documents)) &&
                (!tsPiece.envoiPostal || aidePiece.modeTransmission !== 'ENVOIEPOSTAL')
              );
            });
          }
        });
        return error;
      });

      return !badCompletude;
    };

    /**
     * Evaluate conditionAffichage (visible/hidden) setting on a piece
     *
     * @function isPieceVisible
     * @param {object} piece the piece
     * @returns {boolean} the evaluate condition
     */
    $scope.isPieceVisible = function (piece) {
      return mapIsPieceVisible.get(piece.reference);
    };

    /**
     * Evaluate conditionObligatoire (required) setting on a piece
     *
     * @function isPieceRequired
     * @param {object} piece the piece
     * @returns {boolean} the evaluate condition
     */
    $scope.isPieceRequired = function (piece) {
      return piecesService.evalConditionPiece($scope, piece, 'conditionObligatoire');
    };

    /**
     * Check if piece is in available in scope
     *
     * @param {object} piece
     * @returns {boolean}
     */
    function isPieceAvailable(piece) {
      return $scope.isPieceVisible(piece) && _.find($scope.pieces, (p) => p.reference === piece.reference);
    }

    /**
     * Check if ligne has amount
     *
     * @param {object} ligne to check
     * @returns {boolean} true if line has an amount
     */
    $scope.hasAmount = (ligne) => {
      return [_.get(ligne, `montant.ttc`), _.get(ligne, 'montant.ht')].some(
        (montant) => _.isNumber(montant) && montant !== 0
      );
    };

    /**
     * Format postes with documents to be displayed correctly.
     *
     * @param {Array} postesWithDocuments
     * @returns {object} new format of postes with documents
     */
    function formatPiecesToAssociate(postesWithDocuments) {
      const newFormatPostesWithDocuments = [];

      // Get all the pieces associated to the postes.
      let pieces = _.flatMap(postesWithDocuments, 'piecesNames');
      pieces = _.uniq(pieces);

      // Look all the pieces to attach the postes and categories related.
      _.forEach(pieces, (piece, i) => {
        const allPosteAssociatedToPiece = _.filter(postesWithDocuments, (poste) => poste.piecesNames.includes(piece));
        newFormatPostesWithDocuments.push({ pieceName: piece, relatedPostes: [] });

        _.forEach(allPosteAssociatedToPiece, (poste) => {
          // Check if the object to return has already an item with the same piece name and category name.
          const [item] = JSONPath(
            `$..[?(@.pieceName == "${piece}")]..relatedPostes[?(@.categoryName=="${poste.categoryName}")]`,
            newFormatPostesWithDocuments
          );

          // If not create a new related poste item.
          if (_.isEmpty(item)) {
            newFormatPostesWithDocuments[i].relatedPostes.push({
              categoryName: poste.categoryName,
              postesNames: [poste.posteName],
            });
          } // Otherwise, push only the posteName in the existing related poste item.
          else {
            item.postesNames.push(poste.posteName);
          }
        });
      });

      return newFormatPostesWithDocuments;
    }

    /**
     * Set pieces validity (lock/unlock the usage of the next button)
     *
     * @param {boolean} isValid
     * @returns {void}
     */
    $scope.setPiecesValidity = (isValid) => {
      $scope.navigate.block = !isValid;
    };

    /**
     * Set Map is pieces visible
     *
     * @returns {object} map piece is visible
     */
    function setMapIsPieceVisible() {
      const excludedReferences = ['recapitulatif'];
      const mapIsPieceVisible = new Map();
      let pieceList;
      if (
        $scope.kind === 'contribution' &&
        $scope.entity.typeContribution === contributionsConstants.type.MODIFICATION
      ) {
        pieceList = $scope.aide.pieces.filter((aidePiece) => {
          return $scope.entity.piecesNonConformes.some(
            (pieceNonConforme) => pieceNonConforme.reference === aidePiece.reference
          );
        });
      } else {
        pieceList = [...$scope.aide.pieces];
      }
      // Calculate the visibility only once.
      pieceList.forEach((piece) => {
        // Some pieces should not be shown even if they are in aide.pieces
        const isReferenceExcluded = excludedReferences.includes(piece.reference);
        const isVisible = !isReferenceExcluded && piecesService.evalConditionPiece($scope, piece, 'conditionAffichage');
        // Add to the map
        mapIsPieceVisible.set(piece.reference, isVisible);
        if (!isVisible && !isReferenceExcluded) {
          // Check for non visible documents
          piecesService.cleanNotVisiblePieces($scope.aide, $scope.contribution, piece).then((shouldEmptyDocuments) => {
            // If documents have been deleted, we remove them from scope.
            if (shouldEmptyDocuments) {
              // reset piece in $scope.aide
              _.set(
                _.find($scope.aide.pieces, (p) => p.reference === piece.reference),
                'documents',
                []
              );
            }
          });
        }
      });
      return mapIsPieceVisible;
    }

    /**
     * Filter pieces by current famille
     *
     * @param {string} famille famille href
     * @returns {Array<object>} sorted pieces array
     */
    function filterPiecesByFamille(famille) {
      $scope.pageOptions.modelesPieces.forEach((modelePiece) => {
        const pieceFamilles = modelePiece.familles;

        // If current modele piece concern current famille
        if (Array.isArray(pieceFamilles) && pieceFamilles.some((piece) => piece.href === famille)) {
          // If piece does not exist in current pieces array
          if (!$scope.aide.pieces.find((piece) => piece.reference === modelePiece.reference)) {
            $scope.aide.pieces.push(new Piece(modelePiece));
          }
        }

        // If current modele piece don't concern current famille
        if (Array.isArray(pieceFamilles) && !pieceFamilles.some((piece) => piece.href === famille)) {
          // if current modele exist on piece array
          if ($scope.aide.pieces.find((piece) => piece.reference === modelePiece.reference)) {
            // remove piece because it matches with another famille
            _.remove($scope.aide.pieces, (piece) => piece.reference === modelePiece.reference);
          }
        }
      });
      // Using sortBy to keep teleservice configuraton order
      return _.sortBy($scope.aide.pieces, (piece) =>
        _.findIndex($scope.pageOptions.modelesPieces, (modelePiece) => modelePiece.reference === piece.reference)
      );
    }

    /**
     * Before upload piece
     *
     * @param {boolean} updatingDescription
     * @returns {void}
     */
    $scope.beforeUploadPiece = function (updatingDescription = false) {
      $scope.pieceUploadInProgress = true;
      if (!updatingDescription) {
        $scope.navigate.lockNavigation();
      }
    };

    /**
     * After upload piece
     *
     * @param {boolean} updatingDescription
     * @param {boolean} success
     * @returns {void}
     */
    $scope.afterUploadPiece = function (updatingDescription = false, success = false) {
      $scope.pieceUploadInProgress = false;
      if (!updatingDescription) {
        $scope.navigate.unlockNavigation();
      }

      if ($scope.currentlyClickingNextBtn) {
        $scope.currentlyClickingNextBtn = false;
        if (success) {
          $scope.navigate.next();
        }
      }
    };
  },
]);
