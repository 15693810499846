'use strict';

angular.module('portailDepotDemandeAide.dashboard').controller('dashboardDemandesAidesController', [
  '$rootScope',
  '$scope',
  '$log',
  '$window',
  'configuration',
  'tiers',
  '$state',
  '$sce',
  '$translate',
  'defaultHelpHtmlContent',
  'jwtSessionService',
  '$stateParams',
  'showSearch',
  'IFrameCommunicationManager',
  'alertsService',
  function (
    $rootScope,
    $scope,
    $log,
    $window,
    configuration,
    tiers,
    $state,
    $sce,
    $translate,
    defaultHelpHtmlContent,
    jwtSessionService,
    $stateParams,
    showSearch,
    IFrameCommunicationManager,
    alertsService
  ) {
    'use strict';
    $scope.tiers = tiers;
    $scope.pagination = $stateParams.page;

    // Search filters
    this.aideSearchMem = null;
    $scope.showSearch = showSearch;
    $window.scrollTo(0, 0);

    // Accès à l'Extranet V8 : récupération du code du tiers V8 éventuellement corrélé au tiers
    let codeTiersV8;

    if (tiers?.correlations?.length > 0) {
      const tenantId = configuration.tenant?.id;
      const refAppliExterneTiersV8 = '/referentiel-tiers/'.concat(tenantId, '/applicationsExternes/TIERSV8');
      const correlationTiersV8 = tiers.correlations.find(({ reference }) => reference === refAppliExterneTiersV8);

      if (correlationTiersV8) {
        codeTiersV8 = correlationTiersV8.detail?.inputReference;
      }
    }

    // Accès à l'Extranet V8 : construction de l'affichage du lien vers l'Extranet V8 dans le "HTML haut"
    let helpHtmlContent = '';

    if (!_.isEmpty(codeTiersV8) && !_.isEmpty(configuration.liensExternes)) {
      const lienExterneExtranetV8 = _.find(configuration.liensExternes, { type: 'EXTRANETV8' });

      if (!_.isEmpty(lienExterneExtranetV8) && !_.isEmpty(lienExterneExtranetV8.url)) {
        const donneesLienExterneExtranetV8 = {
          urlExtranetV8: lienExterneExtranetV8.url,
          codeTiers: codeTiersV8,
          originAuthentification: 'PDAFRONT',
          jwt: jwtSessionService.jwt(),
        };

        helpHtmlContent = $translate.instant(
          'connected.dashboard.aides.demandesAides.lienExterneExtranetV8',
          donneesLienExterneExtranetV8
        );
      }
    }

    // Handle the justificationOnly mode
    const state = $state.$current;
    state.data = $state.$current.data || {};
    // Update the title and the help text
    state.data.title = $translate.instant('connected.dashboard.aides.demandesAides.title');

    helpHtmlContent = helpHtmlContent + $translate.instant(defaultHelpHtmlContent);
    $scope.helpHtmlContent = helpHtmlContent;

    this.iFrameCommunicationManager = new IFrameCommunicationManager('#demandesFinancementIframe');

    // util
    const formatBuckets = (item = {}) => (item.buckets ?? []).map(({ key }) => key);

    // Manage list-demandes iframe events
    this.onIframeEvent = ({ data = {} }) => {
      const { route, action, value } = data;

      if (route === 'demandes-financement-view.ux') {
        switch (action) {
          case 'update-filters': // 'update-filters' event is meant to set aides-filter component selectable values
            if (value) {
              $scope.$broadcast(action, {
                exercicesBudgetaires: formatBuckets(value.exerciceBudgetaire),
                statuses: formatBuckets(value.status),
                teleservices: formatBuckets(value.libelleTeleservice),
              });
            }
            break;

          case 'display-error': // show an error alert
            $log.error(`An error occurred trying to recover the demandes with message : ${value.message}`);
            $rootScope.$broadcast('alerts', alertsService.getAlertError($translate.instant('common.error.happened')));
            throw value;
        }
      }
    };
    this.iFrameCommunicationManager.manageEvent(this.onIframeEvent);

    /**
     * Search aides with filters
     *
     * @param {object} aideSearch object with filters
     * @returns {Promise<void> | void}
     */
    $scope.searchAides = (aideSearch) => {
      // Check if search is necessary
      if (_.isEqual(this.aideSearchMem, aideSearch)) return;

      this.aideSearchMem = aideSearch;

      this.iFrameCommunicationManager.sendEvent({
        action: 'search',
        data: aideSearch,
      });
    };

    $scope.$on('$destroy', () => {
      this.iFrameCommunicationManager.close();
    });
  },
]);
