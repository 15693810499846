import { asDate } from '@mgdis/date-utils';

/**
 * Returns a function that formats a date value according to the specified style and locale.
 *
 * @returns {Function} A function that takes a date value as its first argument, and an optional style string as its second argument. The function returns a formatted date string.
 */
function asDateFilter() {
  return (dateValue, options) => {
    const mergedOptions = { locale: 'fr', ...options };
    return asDate(dateValue, mergedOptions);
  };
}

export { asDateFilter };
