'use strict';
angular.module('common.services').factory('localisationsService', [
  'configuration',
  'jwtSessionService',
  function (configuration, jwtSessionService) {
    'use strict';

    return {
      /**
       * Indique si la localisation est active sur l'espace usagers.
       * La localisation est active sur l'espace usagers si la
       * localisation est active (globalement) ET si au moins une hiérarchie
       * est visible sur l'espace usagers.
       *
       * @param {object} teleserviceConfiguration
       * @returns {boolean}
       */
      isLocationActiveOnClaimantPortal: function (teleserviceConfiguration) {
        var pageOptions = _.get(teleserviceConfiguration, 'workflow.pageInformationsGenerales', {});

        var localisationActif = _.get(pageOptions, 'informationsLocalisation.actif', false);

        // Récupération des hiérarchies de localisation actives sur le téléservice
        var hierarchies = _.get(pageOptions, 'informationsLocalisation.hierarchies', []);
        var hierarchiesActives = _.filter(hierarchies, 'actif');

        // On s'assure qu'au moins une hiérarchie est visible sur l'espace usagers. Sinon on désactive la localisation.
        var hierarchiesVisible = _.reject(hierarchiesActives, 'hideOnEspaceUsagers');

        return localisationActif && !_.isEmpty(hierarchiesVisible);
      },

      /**
       * Méthode qui retourne l'url de l'iframe pour sélectionner une localisation
       *
       * @param {object} teleserviceConfiguration Paramétrage du téléservice
       * @param {object} localisationsSurDemande Localisations paramétrées sur l'aide
       * @returns {string}
       */
      getLocalisationsIframeUrl: function (teleserviceConfiguration, localisationsSurDemande) {
        // Options de la page "Informations générales"
        var pageOptions = _.get(teleserviceConfiguration, 'workflow.pageInformationsGenerales', {});

        // Récupération des hiérarchies de localisation actives sur le téléservice
        var hierarchies = _.get(pageOptions, 'informationsLocalisation.hierarchies', []);
        var hierarchiesActives = _.filter(hierarchies, 'actif');

        // On s'assure qu'au moins une hiérarchie est visible sur l'espace usagers. Sinon on désactive la localisation.
        var hierarchiesVisible = _.reject(hierarchiesActives, 'hideOnEspaceUsagers');

        // Références des hiérarchies paramétrées sur le téléservice.
        var hierarchiesVisibleReference = _.map(hierarchiesVisible, function (hierarchie) {
          return hierarchie.href.split('/').pop();
        });

        var queryStringHierarchies = _.join(hierarchiesVisibleReference, ',');

        // Références des localisations éventuellement présentes sur la demande.
        // On affiche uniquement les localisations issues de hiérarchies visibles sur la demande.
        var localisationsSurDemandeVisibles = _.filter(localisationsSurDemande, function (localisation) {
          var isLocalisationVisible = _.some(hierarchiesVisible, { href: localisation.hierarchie.href });
          return isLocalisationVisible;
        });
        var localisationsSurDemandeReference = _.map(localisationsSurDemandeVisibles, function (localisation) {
          var referenceLocalisation = localisation.href.split('/').pop();
          var referenceHierarchie = localisation.hierarchie.href.split('/').pop();
          return referenceLocalisation + ':' + referenceHierarchie;
        });
        var queryStringLocalisations = _.join(localisationsSurDemandeReference, ',');

        // Génération de l'URL de l'iframe de sélection de la localisation
        var templateLocalisationIframeSrc =
          configuration.referentielLocalisation.ux +
          '#/<%= tenantId %>/selectionLocalisation?jwtKey=<%= jwtKey %>' +
          '&hierarchies=<%= qsHierarchies %>&selected=<%= qsLocalisation %>&readOnly=<%= qsReadOnly %>&theme=demandeur';

        var compiledLocalisationIframeSrc = _.template(templateLocalisationIframeSrc);

        return compiledLocalisationIframeSrc({
          tenantId: configuration.tenant.id,
          jwtKey: jwtSessionService.getJwtKey(),
          qsHierarchies: queryStringHierarchies,
          qsLocalisation: queryStringLocalisations,
          qsReadOnly: false,
        });
      },
    };
  },
]);
