'use strict';
angular.module('portailDepotDemandeAide.rattachement').controller('rattachementController', [
  '$scope',
  '$modal',
  '$translate',
  '$log',
  '$q',
  'rattachementId',
  'tiers',
  'alertsService',
  'tiersRattachementService',
  'cmisService',
  function (
    $scope,
    $modal,
    $translate,
    $log,
    $q,
    rattachementId,
    tiers,
    alertsService,
    tiersRattachementService,
    cmisService
  ) {
    'use strict';

    $scope.allowed = false;
    $scope.answered = false;

    $scope.urlFileIcons = 'resources/images/file-icons/';

    /**
     * Initialize documents pieces
     *
     * @param {Array<object>} pieces
     * @returns {Promise<Array>}
     */
    function initialisationDocumentPieces(pieces) {
      const promises = (pieces || [])
        .filter(({ reference }) => reference !== 'recapitulatif')
        .flatMap((piece) => {
          return (piece.documents || []).map((document) => cmisService.expandDocument(document, false));
        });

      return $q
        .all(promises)
        .then(function () {
          return pieces;
        })
        .catch(function (error) {
          $log.error('Error while getting metadatas: ', error);
        });
    }

    // Check rattachement
    tiersRattachementService.getDemandeRattachement(rattachementId).then(function (response) {
      $scope.rattachement = response.data;

      if (response.status === 403) {
        $scope.$broadcast(
          'alerts',
          alertsService.getAlertError('connected.rattachement.errors.forbidden'),
          'validation-rattachement-alerts'
        );
      }

      if (response.status !== 403 && _.isEmpty($scope.rattachement)) {
        $scope.$broadcast(
          'alerts',
          alertsService.getAlertError('connected.rattachement.errors.notExist'),
          'validation-rattachement-alerts'
        );
      }

      if (response.status === 200) {
        $scope.rattachement = response.data;

        if (!_.isEmpty(tiers)) {
          // Checks if the connected user is the administrator of the rattachement request's structure
          if (
            $scope.rattachement.siret.siren === tiers.SIRET.SIREN &&
            $scope.rattachement.siret.nic === tiers.SIRET.NIC
          ) {
            var checkAdmin = _.find($scope.tiers.linkedUsers, function (linkedUser) {
              return linkedUser.expand.userName === $scope.currentUser.userName && linkedUser.form === 'ADMINISTRATOR';
            });
            $scope.allowed = !!checkAdmin;
          }

          if (!$scope.allowed) {
            $scope.$broadcast(
              'alerts',
              alertsService.getAlertError('connected.rattachement.errors.forbidden'),
              'validation-rattachement-alerts'
            );
          }

          // Extension des pieces avec les métadonnées
          initialisationDocumentPieces($scope.rattachement.pieces || []).then(function (pieces) {
            $scope.pieces = pieces;
          });
        }
      }
    });

    // Check tiers
    $scope.tiers = tiers;
    $scope.$watch($scope.tiers, function () {
      if (_.isEmpty($scope.tiers)) {
        $scope.$broadcast(
          'alerts',
          alertsService.getAlertError('connected.rattachement.errors.getTiers'),
          'validation-rattachement-alerts'
        );
      }
    });

    // Functions for accept of refuse a rattachement request
    $scope.validDemandeRattachement = function (status) {
      if ($scope.allowed) {
        $scope.rattachement.status = status;
        $scope.rattachement.tiers = {};
        $scope.rattachement.tiers.href = tiers.id;
        $scope.rattachement.tiers.title = tiers.raisonSociale;

        tiersRattachementService.validDemandeRattachement($scope.rattachement).then(
          function (response) {
            if (response.status === 200 || response.status === 201) {
              $scope.answered = true;
              $scope.$broadcast(
                'alerts',
                alertsService.getAlertSuccess('connected.rattachement.success.' + status),
                'validation-rattachement-alerts'
              );
            }
          },
          function (response) {
            // User account is already attached
            if (response.status === 406 && _.get(response, 'data.code') === '406.12') {
              $scope.$broadcast(
                'alerts',
                alertsService.getAlertError('connected.rattachement.errors.alreadyAttached'),
                'validation-rattachement-alerts'
              );
            }
          }
        );
      }
    };

    // Opens a pop-up to confirm the refusal
    $scope.refuseDemandeRattachement = function () {
      $modal({
        scope: $scope.$new(),
        template: 'rattachement/modal-refuse-rattachement.html',
      });
    };

    // Close pop-up and refuse the rattachement request
    $scope.confirmRefus = function (modal) {
      modal.$hide();
      $scope.rattachement.motif = $translate.instant('connected.rattachement.refuse-motive');
      $scope.validDemandeRattachement('refused');
    };
  },
]);
