angular.module('portailDepotDemandeAide', [
  'domain',
  'portailDepotDemandeAide.user',
  'portailDepotDemandeAide.rattachement',
  'portailDepotDemandeAide.tiersSelection',
  'portailDepotDemandeAide.dashboard',
  'portailDepotDemandeAide.tiers',
  'portailDepotDemandeAide.depot',
  'portailDepotDemandeAide.templates',
  'portailDepotDemandeAide.contact',
  'configuration',
  'form',
  'alerts',
  'common',
  'contributions',
  'persistence',
  'user.directives',
  'user.services',
  'user.constants',
  'i18n.translate',
  'i18n.localization',
  'tiers',
  'aides',
  'paiements',
  'offresStage',
  'comitesRecevabilite',
  'justifications',
  'reports',
  'dossiers',
  'components',
  'kendo.directives',
  'ui.router',
  'ui.bootstrap.pagination',
  'ui.bootstrap.collapse',
  'ui.bootstrap.tpls',
  'ui.bootstrap.tabs',
  'ui.bootstrap.popover',
  'ui.bootstrap.modal',
  'ui.bootstrap.tooltip',
  'angular-loading-bar',
  'ngSanitize',
  'ngAria',
  'angularFileUpload',
  'ncy-angular-breadcrumb',
  'angular-bind-html-compile',
  'vcRecaptcha',
  'ngMessages',
  'ngToast',
  'ngMatomo',
  'ngConcurrenceInterceptor',
  'mgExpressions',
  'ngCookies',
]);

angular.module('portailDepotDemandeAide').constant('jsonpatch', window.jsonpatch); // eslint-disable-line angular/window-service
angular.module('portailDepotDemandeAide').config([
  '$httpProvider',
  '$logProvider',
  '$urlRouterProvider',
  '$stateProvider',
  '$ariaProvider',
  '$translateProvider',
  '$sanitizeProvider',
  'translateLoaderModuleProvider',
  'jwtSessionServiceProvider',
  'jwtSessionStorageProvider',
  'ngToastProvider',
  'configuration',
  '$compileProvider',
  'localizationServiceProvider',
  '$locationProvider',
  '$provide',
  function (
    $httpProvider,
    $logProvider,
    $urlRouterProvider,
    $stateProvider,
    $ariaProvider,
    $translateProvider,
    $sanitizeProvider,
    translateLoaderModuleProvider,
    jwtSessionServiceProvider,
    jwtSessionStorageProvider,
    ngToastProvider,
    configuration,
    $compileProvider,
    localizationServiceProvider,
    $locationProvider,
    $provide
  ) {
    'use strict';

    // Hack for 1.6 https://docs.angularjs.org/guide/migration#commit-aa077e8
    $locationProvider.hashPrefix('');

    // Hack for Angular 1.7 https://stackoverflow.com/a/51563635/4614982
    angular.lowercase = angular.$$lowercase;

    $httpProvider.useApplyAsync(true);
    $compileProvider.debugInfoEnabled(false);

    // Set custom elements that should not be stripped on sanitize (need to be added in the DOM)
    $sanitizeProvider.addValidElements({ htmlElements: ['mg-icon'] });
    $sanitizeProvider.addValidAttrs(['icon']);

    $ariaProvider.config({
      tabindex: false,
    });

    // LMN - 15/12/2016 - Passage à Lodash 4
    // Wrapping des fonctions en fin de sprint
    // TODO: Faire la vraie migration dans les sous modules
    (function () {
      _.all = _.every;
      _.any = _.some;
      _.backflow = _.flowRight;
      _.callback = _.iteratee;
      _.collect = _.map;
      _.compose = _.flowRight;
      _.contains = _.includes;
      _.detect = _.find;
      _.foldl = _.reduce;
      _.foldr = _.reduceRight;
      _.findWhere = _.find;
      _.first = _.head;
      _.include = _.includes;
      _.indexBy = _.keyBy;
      _.inject = _.reduce;
      _.invoke = _.invokeMap;
      _.modArgs = _.overArgs;
      _.methods = _.functions;
      _.object = _.fromPairs;
      _.padLeft = _.padStart;
      _.padRight = _.padEnd;
      _.pairs = _.toPairs;
      _.pluck = _.map;
      _.rest = _.tail;
      _.restParam = _.rest;
      _.select = _.filter;
      _.sortByOrder = _.orderBy;
      _.trimLeft = _.trimStart;
      _.trimRight = _.trimEnd;
      _.trunc = _.truncate;
      _.unique = _.uniq;
      _.where = _.filter;
    })();
    //wrapLodashFunction();

    // Interceptor to add a jwt token to each request
    $httpProvider.interceptors.push('jwtAuthInterceptor');

    // Interceptor to redirect calls to MDM to the server proxy route
    $httpProvider.interceptors.push('mdmRequestsInterceptor');

    // Called on every HTTP requests to display loading icon, errors etc.
    $httpProvider.interceptors.push('portalInterceptor');

    // Called on every HTTP requests to handle concurrence on specific requests.
    $httpProvider.interceptors.push('concurrenceInterceptor');

    // Activate log in debug mode
    $logProvider.debugEnabled = true;

    ngToastProvider.configure({
      // verticalPosition: 'bottom',
      horizontalPosition: 'center',
      animation: 'fade',
      maxNumber: 1,
    });

    $translateProvider.useLoader('translateLoaderModule', {
      templatesUrl: configuration.i18n.pattern,
      teleservice: configuration.i18n.teleservice,
    });

    $translateProvider.use('fr'); // Use 'localizationServiceProvider.getActiveLocale()' to get language according to user settings
    // Load root translation on init
    translateLoaderModuleProvider.addModule('root');

    // On root url, redirect to home if there's only one tenant
    $stateProvider.state('root', {
      url: '/',
      template: `<div ng-if="displayMultiTenantMessage">{{'multitenant.message'|translate}}</div>`,
      resolve: {
        /* @ngInject */
        checkTenant: [
          '$state',
          '$log',
          '$rootScope',
          'tenantsService',
          ($state, $log, $rootScope, tenantsService) => {
            tenantsService
              .getMonoTenantId()
              .then((tenantId) => {
                if (tenantId) {
                  $state.go('app.home', { tenantId });
                }
              })
              .catch((error) => {
                $log.error('get mono-tenant error : ' + _.get(error.data.message));
                $rootScope.displayMultiTenantMessage = true;
              });
          },
        ],
      },
    });

    $stateProvider
      .state('app', {
        // Keep compatibility with override configurations
        url: (configuration.tenant ? null : '/:tenantId') + '?error',
        abstract: true,
        template: '<ui-view/>',
        resolve: {
          settings: [
            '$stateParams',
            '$q',
            '$http',
            '$translate',
            'translateLoaderModule',
            'configurationService',
            'configuration',
            '$rootScope',
            '$location',
            '$state',
            'mdmService',
            'jwtSessionStorage',
            'matomoService',
            function (
              $stateParams,
              $q,
              $http,
              $translate,
              translateLoaderModule,
              configurationService,
              configuration,
              $rootScope,
              $location,
              $state,
              mdmService,
              jwtSessionStorage,
              matomoService
            ) {
              // We create a promise in $rootScope to force child states to wait this promise to resolve its own resolves
              // cf https://github.com/angular-ui/ui-router/issues/1903
              // ui-router 0.x uses by default an eager behaviour instead of a lazy one like ui-router 1.0
              const defer = $q.defer();
              $rootScope.configurationPromise = defer.promise;

              jwtSessionStorageProvider.jwtKey('jwt-' + $stateParams.tenantId + '-portail-depot-demande-aides');
              configurationService.fillConfigurationWithTenantInformation($stateParams.tenantId);
              jwtSessionServiceProvider.authenticationServiceUrl(_.get(configuration, 'user.accountManagement'));
              configuration.tiers.ux = configuration.tiers.ux.replace('{{tenantId}}', $stateParams.tenantId);

              // Keep compatibility with override configurations
              if (configuration.tenant) {
                // Authentication
                jwtSessionStorageProvider.jwtKey('jwt-' + configuration.tenant.id + '-portail-depot-demande-aides');
                jwtSessionServiceProvider.authenticationServiceUrl(_.get(configuration, 'user.accountManagement'));
                defer.resolve(configuration);
                return configuration;
              }

              return $http
                .get('./admin/tenants/' + $stateParams.tenantId + '/publicSettings?merge=true')
                .then(function (publicSettings) {
                  // Keep compatibility with old overriding configurations methods.
                  configuration.tenant = {
                    id: $stateParams.tenantId,
                  };

                  // Merge configuration
                  _.merge(configuration, publicSettings.data);

                  configuration.timeZone = publicSettings.headers('x-tz') || configuration.timeZone;
                  const timezoneOffset = moment(new Date()).tz(configuration.timeZone).format('ZZ');
                  const isUserTZMatchingWithServerTZ = timezoneOffset === moment(new Date()).format('ZZ');

                  // Force time zone offset : the date shouldn't depends on the time zone of the user
                  $provide.decorator('dateFilter', [
                    '$delegate',
                    function ($delegate) {
                      return (date, format) => {
                        const formattedDate = $delegate(new Date(date), format, timezoneOffset);
                        // Display the time zone for foreign users
                        return !isUserTZMatchingWithServerTZ &&
                          format &&
                          format.match(/H|^short$|^mediumTime$|^medium$/)
                          ? `${formattedDate} (${configuration.timeZone})`
                          : formattedDate;
                      };
                    },
                  ]);

                  // Update mdmService configuration
                  mdmService.configuration(configuration);

                  // Matomo
                  const matomoOptions = configuration.analytics?.matomo ?? {};
                  if (matomoOptions.active) {
                    matomoService.enableTracking(matomoOptions);
                  }

                  // Set footer links
                  const baseUrl = $location.absUrl().split('#')[0];
                  // ----- Verification de parametrage du lien Mentions Legales ----
                  // Si l'otpion de paramétrage de surcharge est actif
                  // Alors on recupere le lien paramétre dans les publicSettings

                  const urlMentions =
                    baseUrl +
                    $state.href('app.mentions-legales', {
                      tenantId: configuration.tenant.id,
                    });

                  const urlContact =
                    baseUrl +
                    $state.href('app.contact-page', {
                      tenantId: configuration.tenant.id,
                    });

                  const mlLink = _.get(configuration, 'displayLegalNotices', false)
                    ? _.isEmpty(_.get(configuration, 'legalNoticesLink', urlMentions))
                      ? urlMentions
                      : _.get(configuration, 'legalNoticesLink', urlMentions)
                    : urlMentions;

                  const cLink = _.get(configuration, 'displayContactUs', false)
                    ? _.isEmpty(_.get(configuration, 'contactUsLink', urlContact))
                      ? urlContact
                      : _.get(configuration, 'contactUsLink', urlContact)
                    : urlContact;

                  const faqLink =
                    baseUrl +
                    $state.href('app.fag', {
                      tenantId: configuration.tenant.id,
                    });

                  const accessibilityLink =
                    baseUrl +
                    $state.href('app.accessibilite', {
                      tenantId: configuration.tenant.id,
                    });

                  const personalDataLink =
                    baseUrl +
                    $state.href('app.donnees-personnelles', {
                      tenantId: configuration.tenant.id,
                    });

                  $rootScope.footer = {
                    mentionsLegales: {
                      url: mlLink,
                      target: mlLink === urlMentions ? '_self' : '_blank',
                    },

                    contact: {
                      url: cLink,
                      target: cLink === urlContact ? '_self' : '_blank',
                    },

                    faq: {
                      url: faqLink,
                    },

                    accessibility: {
                      url: accessibilityLink,
                      target: '_self',
                    },

                    personalData: {
                      url: personalDataLink,
                      target: '_self',
                    },
                  };

                  /**
                   * Function to set chatbot active value from contex
                   *
                   * @returns {boolean}
                   */
                  function isChatBotConfigurationActive() {
                    // 1. we check if chatbot feature is active from config
                    const isFeatureActivate = $rootScope.currentUser
                      ? _.get(configuration, 'chatbot.active', false)
                      : false;

                    // 2. we check if url is on of print view to pdf (demande aide, demande paiement, demande justification)
                    const urlPath = _.get(window, 'location.hash', '').split('?')[0] || '';
                    const mustNotDisplayInView = _.isEmpty(
                      _.intersection(['printAide', 'printRecapPaiement', 'printRecapJustification'], urlPath.split('/'))
                    );

                    return isFeatureActivate && mustNotDisplayInView;
                  }

                  //Chatbot configuration
                  $rootScope.chatbotConfiguration = {
                    active: isChatBotConfigurationActive(),
                    url:
                      '/referentiel-echanges/#/' +
                      configuration.tenant.id +
                      '/chatbot?jwtKey=' +
                      jwtSessionStorage.getJwtKey(),
                  };

                  // Mode hors connection
                  // Ajout du tenant a l'url si inexistant - sur mentions legales
                  if (!_.isMatch($rootScope.footer.mentionsLegales.url, $stateParams.tenantId)) {
                    $rootScope.footer.mentionsLegales.url = _.replace(
                      $rootScope.footer.mentionsLegales.url,
                      '#//',
                      '#/' + $stateParams.tenantId + '/'
                    );
                  }
                  // Ajout du tenant a l'url si inexistant - sur nous contacter
                  if (!_.isMatch($rootScope.footer.contact.url, $stateParams.tenantId)) {
                    $rootScope.footer.contact.url = _.replace(
                      $rootScope.footer.contact.url,
                      '#//',
                      '#/' + $stateParams.tenantId + '/'
                    );
                  }
                  // Ajout du tenant a l'url si inexistant - sur faq
                  if (!_.isMatch($rootScope.footer.faq.url, $stateParams.tenantId)) {
                    $rootScope.footer.faq.url = _.replace(
                      $rootScope.footer.faq.url,
                      '#//',
                      '#/' + $stateParams.tenantId + '/'
                    );
                  }

                  translateLoaderModule.updateContext({
                    tenantId: $stateParams.tenantId,
                  });

                  // Add module force refresh translations. Just call $translate.refresh is not enough in our case.
                  // We had just one module but all urls in the configuration "i18n.pattern" are reload
                  translateLoaderModuleProvider.addModule('alerts');
                  translateLoaderModuleProvider.addModule('tiers');
                  translateLoaderModuleProvider.addModule('aides');
                  translateLoaderModuleProvider.addModule('form');
                  translateLoaderModuleProvider.addModule('user');
                  translateLoaderModuleProvider.addModule('portal');
                  translateLoaderModuleProvider.addModule('justification');
                  translateLoaderModuleProvider.addModule('teleservice-offres-stage-default');
                  translateLoaderModuleProvider.addModule('offreStage');
                  translateLoaderModuleProvider.addModule('contributions');
                  translateLoaderModuleProvider.addModule('teleservice-contributionAvis-default');
                  translateLoaderModuleProvider.addModule('teleservice-demandePaiement-default');

                  return $translate.refresh();
                })
                .then(function () {
                  return configuration;
                })
                .finally(function () {
                  defer.resolve(configuration);
                  if (configuration && configuration.tenant) {
                    // Authentication
                    jwtSessionStorageProvider.jwtKey('jwt-' + configuration.tenant.id + '-portail-depot-demande-aides');
                    jwtSessionServiceProvider.authenticationServiceUrl(_.get(configuration, 'user.accountManagement'));
                  }
                });
            },
          ],

          // fetch the referentiel-tiers i18n translations without the i18n module because we only use 3 labels
          referentielTiersI18n: [
            'tiersService',
            (tiersService) => {
              return tiersService.getReferentielTiersI18n();
            },
          ],
        },
      })
      .state('app.home', {
        // This state is for unconnected arrivals
        url: '/?redirectTo&redirectParams&username&token&loggedOut&success',
        // templateUrl: 'home/home.html',
        // controller: 'homeController',
        controller: [
          '$rootScope',
          '$window',
          '$stateParams',
          '$state',
          'userSessionService',
          '$location',
          '$translate',
          function ($rootScope, $window, $stateParams, $state, userSessionService, $location, $translate) {
            $rootScope.currentUser = $rootScope.currentUser || userSessionService.getUser();
            if (
              !$rootScope.currentUser &&
              $stateParams.redirectTo &&
              (_.includes($stateParams.redirectTo, 'connected') || _.includes($stateParams.redirectTo, 'app.home'))
            ) {
              const baseUrl = $location.absUrl().split('#')[0];
              const footerArray = [];

              // Build footer param

              // Accessibility
              if (configuration.displayAccessibility) {
                footerArray.push(
                  encodeURIComponent($rootScope.footer.accessibility.url) +
                    ',' +
                    $translate.instant('footer.accessibilite') +
                    ',' +
                    $rootScope.footer.accessibility.target
                );
              }

              // Verification parametrage actif : Mentions Legales
              if (configuration.displayLegalNotices) {
                footerArray.push(
                  encodeURIComponent($rootScope.footer.mentionsLegales.url) +
                    ',' +
                    $translate.instant('footer.legal') +
                    ',' +
                    $rootScope.footer.mentionsLegales.target
                );
              }

              // PersonnalData
              if (configuration.displayPersonalData) {
                footerArray.push(
                  encodeURIComponent($rootScope.footer.personalData.url) +
                    ',' +
                    $translate.instant('footer.donnees-personnelles') +
                    ',' +
                    $rootScope.footer.personalData.target
                );
              }

              // Verification parametrage actif : Nous Contacter
              if (configuration.displayContactUs) {
                footerArray.push(
                  encodeURIComponent($rootScope.footer.contact.url) +
                    ',' +
                    $translate.instant('footer.contact') +
                    ',' +
                    $rootScope.footer.contact.target
                );
              }

              const redirectTo = $state.href($stateParams.redirectTo, JSON.parse($stateParams.redirectParams));
              let loginUrl = '/account-management/' + $stateParams.tenantId + '-demandeurs/ux/#/login';
              loginUrl +=
                '?redirectTo=' +
                encodeURIComponent(baseUrl + redirectTo) +
                '&jwtKey=jwt-' +
                $stateParams.tenantId +
                '-portail-depot-demande-aides' +
                '&footer=' +
                footerArray.join(';');
              if ($stateParams.error) {
                loginUrl += '&error=' + $stateParams.error;
              }
              if ($stateParams.success) {
                loginUrl += `&success=${$stateParams.success}`;
              }
              $window.location.assign(loginUrl);
            } else {
              if ($stateParams.redirectTo) {
                $state.go($stateParams.redirectTo, JSON.parse($stateParams.redirectParams));
              } else {
                $state.go('app.connected.tiers-selection');
              }
            }
          },
        ],

        controllerAs: 'home',
      })
      .state('app.mentions-legales', {
        url: '/mentions-legales',
        templateUrl: 'mentions-legales/mentions-legales.html',
        controller: function () {},
        controllerAs: 'mentionsLegales',
      })
      .state('app.contact', {
        //We open the contact page like a modal
        url: '/contact',
        onEnter: [
          '$modal',
          '$state',
          '$rootScope',
          'mdmService',
          'viewManagerService',
          'settings',
          'tiersRattachementService',
          function ($modal, $state, $rootScope, mdmService, viewManagerService, tiersRattachementService) {
            mdmService.fill(['motifscontact']).then(function (mdm) {
              viewManagerService.getViewConfiguration('contact', 'form').then(function (contactConfiguration) {
                tiersRattachementService.getRattachementsConfig().then(function (rattachementsDocumentConfiguration) {
                  const scopeModal = $rootScope.$new();
                  scopeModal.viewConfiguration = contactConfiguration;
                  scopeModal.mdm = mdm;
                  scopeModal.rattachementsDocumentConfiguration = rattachementsDocumentConfiguration;

                  const oldFrom = $rootScope.previousStateFrom;
                  // We go to the origin page before loading the modal
                  $state.go(oldFrom.name, oldFrom.params).then(function () {
                    $modal({
                      template: 'contact/contact.html',
                      scope: scopeModal,
                    });
                  });
                });
              });
            });
          },
        ],
      })
      .state('app.contact-page', {
        url: '/contact-page',
        templateUrl: 'contact/contact-page.html',
        controller: [
          '$scope',
          '$rootScope',
          'mdm',
          'contactConfiguration',
          'civilites',
          'rattachementsDocumentConfiguration',
          function ($scope, $rootScope, mdm, contactConfiguration, civilites, rattachementsDocumentConfiguration) {
            $scope.viewConfiguration = contactConfiguration;
            $scope.mdm = mdm;
            $scope.civilites = civilites;
            $scope.rattachementsDocumentConfiguration = rattachementsDocumentConfiguration;
          },
        ],
        resolve: {
          civilites: [
            'tiersService',
            function (tiersService) {
              return tiersService.getCivilites();
            },
          ],
          mdm: [
            'settings',
            'mdmService',
            function (settings, mdmService) {
              return mdmService.fill(['motifscontact']).then(function (mdm) {
                return mdm;
              });
            },
          ],
          contactConfiguration: [
            'viewManagerService',
            function (viewManagerService) {
              return viewManagerService.getViewConfiguration('contact', 'form').then(function (contactConfiguration) {
                return contactConfiguration;
              });
            },
          ],
          rattachementsDocumentConfiguration: [
            'tiersRattachementService',
            function (tiersRattachementService) {
              return tiersRattachementService.getRattachementsConfig();
            },
          ],
        },
      })
      .state('app.faq', {
        url: '/faq',
        templateUrl: 'faq/faq.html',
      })
      .state('app.accessibilite', {
        url: '/accessibilite',
        templateUrl: 'accessibilite/accessibilite.html',
        controller: function () {},
        controllerAs: 'accessibility',
      })
      .state('app.donnees-personnelles', {
        url: '/donnees-personnelles',
        templateUrl: 'personal-datas/personal-datas.html',
        controller: 'personalDatasController',
      })
      .state('app.connected', {
        // This is a abstract state to share some logic between all states where a user is necessarily connected
        // might become real if we wish to display a list of configurations to the user
        // (not required by first client CRPL where the list is on their site)
        url: '/connecte?print',
        abstract: true,
        templateUrl: 'layout/portal-connected.html',
        controller: 'connectedController',
        resolve: {
          // get used familles count from ref-tiers
          // this will be stored in storeService
          famillesCount: [
            'tiersService',
            function (tiersService) {
              return tiersService.getFamilleCount();
            },
          ],
          // Get selected tiers data on (re)load.
          selectedTiers: [
            'tiersService',
            function (tiersService) {
              return tiersService.getCurrentTiers();
            },
          ],
          selectableTiers: [
            'tiersService',
            function (tiersService) {
              return tiersService.getAllCurrentUserTiers();
            },
          ],
          // Fetch the tiers of the connected user
          mdm: [
            'settings',
            'mdmService',
            function (settings, mdmService) {
              return mdmService.fill(['pays', 'naf', 'TypesEffectifs', 'TypesDonneesFinancieres', 'financeursAides']);
            },
          ],
          masterdata: [
            'tiersService',
            'mdm',
            function (tiersService, mdm) {
              return tiersService
                .getMasterData([
                  'titresCivilites',
                  'familles',
                  'fonctionsRepresentants',
                  'formesJuridiques',
                  'champsActionTerritoriaux',
                ])
                .then(function (masterdata) {
                  _.set(mdm, 'civilites.array', masterdata.titresCivilites.items);
                  _.set(mdm, 'famillestiers.array', masterdata.familles.items);
                  _.set(mdm, 'fonctionsrepres.array', masterdata.fonctionsRepresentants.items);
                  _.set(mdm, 'formesjuridiques.array', masterdata.formesJuridiques.items);
                  return masterdata;
                });
            },
          ],
          userAccountSettings: /* @ngInject */ [
            '$rootScope',
            'USER_EVENTS',
            ($rootScope, USER_EVENTS) =>
              //Update user informations
              $rootScope.$broadcast(USER_EVENTS.userUpdated),
          ],
          accountPublicSettings: [
            '$stateParams',
            'accountManagementService',
            function ($stateParams, accountManagementService) {
              return accountManagementService.getPublicSettings().then(function (response) {
                return _.get(response, 'data');
              });
            },
          ],
        },
      })
      .state('app.printAide', {
        url: '/printAide/:reference?contributionRef',
        component: 'printAide',
        resolve: {
          mdm: [
            'settings',
            'mdmService',
            (settings, mdmService) => {
              return mdmService.fill(['pays', 'naf', 'TypesEffectifs', 'TypesDonneesFinancieres', 'financeursAides']);
            },
          ],
          masterdata: [
            'tiersService',
            'mdm',
            (tiersService, mdm) => {
              return tiersService
                .getMasterData([
                  'titresCivilites',
                  'familles',
                  'fonctionsRepresentants',
                  'formesJuridiques',
                  'champsActionTerritoriaux',
                ])
                .then((masterdata) => {
                  _.set(mdm, 'civilites.array', masterdata.titresCivilites.items);
                  _.set(mdm, 'famillestiers.array', masterdata.familles.items);
                  _.set(mdm, 'fonctionsrepres.array', masterdata.fonctionsRepresentants.items);
                  _.set(mdm, 'formesjuridiques.array', masterdata.formesJuridiques.items);
                  return masterdata;
                });
            },
          ],
          contribution: [
            '$stateParams',
            'contributionsService',
            ($stateParams, contributionsService) => {
              if ($stateParams.contributionRef) {
                return contributionsService.get($stateParams.contributionRef);
              }
            },
          ],
          aide: [
            '$stateParams',
            'mdm',
            'recapitulatifService',
            ($stateParams, mdm, recapitulatifService) => {
              if ($stateParams.contributionRef) {
                return recapitulatifService.getAideWithContribution(
                  $stateParams.reference,
                  $stateParams.contributionRef,
                  mdm
                );
              } else {
                return recapitulatifService.getAide($stateParams.reference, mdm);
              }
            },
          ],
          tiers: [
            'tiersService',
            'mdm',
            (tiersService, mdm) => {
              return tiersService.getCurrentTiers(mdm);
            },
          ],
          teleservice: [
            'aide',
            'contribution',
            'teleservicesService',
            'configuration',
            '$translate',
            (aide, contribution, teleservicesService, configuration, $translate) => {
              // if contribution REDIRECTION pick the new teleservice, otherwise pick the aide teleservice
              const filter = {
                teleservice:
                  contribution && contribution.typeContribution === 'REDIRECTION'
                    ? contribution.teleservice
                    : aide.teleservice,
              };

              return teleservicesService.getTeleServiceFromObject(filter).then((teleservice) => {
                configuration.i18n.teleservice.id = teleservice.reference;
                configuration.i18n.teleservice.workflow = teleservice.workflow.type;
                $translate.refresh();

                return teleservice;
              });
            },
          ],
          publicSettingsFinancement: [
            'aidesService',
            (aidesService) => {
              return aidesService.getPublicSettingsFinancement();
            },
          ],
        },
      })
      .state('app.printRecapPaiement', {
        url: '/printRecapPaiement/:reference',
        component: 'printRecapPaiementComponent',
        resolve: {
          reference: [
            '$transition$',
            function ($transition$) {
              return $transition$.params().reference;
            },
          ],
          demandePaiement: [
            'reference',
            'demandesPaiementService',
            function (reference, demandesPaiementService) {
              return demandesPaiementService.getDemandePaiement(reference, {
                params: {
                  $expand:
                    'pieces.documents,domiciliationBancaire.pieces.documents,demandeFinancement,demandeFinancement.teleservice,history.begin.user',
                },
              });
            },
          ],
          publicSettingsFinancement: [
            'aidesService',
            function (aidesService) {
              return aidesService.getPublicSettingsFinancement();
            },
          ],
          teleservice: [
            'demandePaiement',
            'teleservicesService',
            'configuration',
            function (demandePaiement, teleservicesService, configuration) {
              const teleserviceId = _.get(demandePaiement, 'teleservicePaiement.href', '').split('/').pop();
              return teleservicesService.getTeleService(teleserviceId).then(function (teleservice) {
                configuration.demandePaiement = teleservice.demandePaiement;
                configuration.ns = 'teleservice';
                teleservice.configuration = configuration;
                teleservice.configuration.workflow = teleservice.workflow[teleservice.workflow.type];
                teleservice.configuration.workflow.type = configuration.i18n.teleservice.workflow;
                return teleservice;
              });
            },
          ],
          mdm: [
            'settings',
            'mdmService',
            function (settings, mdmService) {
              return mdmService.fill(['pays', 'naf', 'TypesEffectifs', 'TypesDonneesFinancieres', 'financeursAides']);
            },
          ],
          aide: [
            'demandePaiement',
            'aidesService',
            function (demandePaiement, aidesService) {
              const demandeFinancementReference = _.get(demandePaiement, 'demandeFinancement.href', '')
                .split('/')
                .pop();
              return aidesService.getForPaiements(demandeFinancementReference);
            },
          ],
          tiers: [
            'tiersService',
            'mdm',
            function (tiersService, mdm) {
              return tiersService.getCurrentTiers(mdm);
            },
          ],
        },
      })
      .state('app.printRecapJustification', {
        url: '/printRecapJustification/:justificationReference',
        component: 'justificationPrintComponent',
        resolve: {
          justificationAndExpandedPieces: [
            '$stateParams',
            'justificationService',
            function ($stateParams, justificationService) {
              const select = [
                'history',
                // Informations de réalisation - Informations Générales
                'miseEnOeuvre',
                'questionBeneficiaire',
                'beneficiaires',
                'nbBeneficiaires',
                'nbQuartiersPrioritaires',
                'dateDebut',
                'dateFin',
                'lieux',
                'objectifs',
                // Informations de réalisation - Moyens humains
                'moyensHumains',
                // Données chiffrées
                'reglesRepartition',
                'explicationEcarts',
                'contributionsVolontaires',
                'observations',
                // Tableau de synthèse
                'tableau',
                'date',
                'active',
                'demandeFinancement',
                'reference',
                'statut',
                'teleservice',
                'tenant',
                'title',
                'user',
                'views',
                'pieces',
                // Raison non réalisation (Téléservice NRA)
                'raisonNonRealisation',
              ];

              return justificationService.getJustificationAndExpandedPieces(
                $stateParams.justificationReference,
                select
              );
            },
          ],

          justification: [
            'justificationAndExpandedPieces',
            (justificationAndExpandedPieces) => justificationAndExpandedPieces.justification,
          ],

          piecesExpanded: [
            'justificationAndExpandedPieces',
            (justificationAndExpandedPieces) => justificationAndExpandedPieces.piecesExpanded,
          ],

          teleserviceJustification: [
            'justification',
            'justificationService',
            function (justification, justificationService) {
              return justificationService.getTeleservice(justification, ['workflow', 'persistenceConfiguration']);
            },
          ],

          demandeFinancement: [
            'justification',
            '$http',
            '$log',
            (justification, $http, $log) =>
              $http
                .get(`${_.get(justification, 'demandeFinancement.href')}?expand=teleservice&$select=teleservice`)
                .then((aide) => _.get(aide, 'data'))
                .catch((error) => {
                  $log.error(`Can't get demandeFinancement from justification ${error}`);
                }),
          ],
        },
      });
  },
]);

angular.module('portailDepotDemandeAide').run([
  '$rootScope',
  '$document',
  '$log',
  '$state',
  '$modal',
  'routeUtilsService',
  'tiersService',
  '$timeout',
  'userSessionService',
  'mdmService',
  'viewManagerService',
  'jwtSessionStorage',
  'jwtSessionService',
  'localizationService',
  '$transitions',
  'configuration',
  'FileUploader',
  'StoreService',
  'tiersRattachementService',
  'themeManagementService',
  function (
    $rootScope,
    $document,
    $log,
    $state,
    $modal,
    routeUtilsService,
    tiersService,
    $timeout,
    userSessionService,
    mdmService,
    viewManagerService,
    jwtSessionStorage,
    jwtSessionService,
    localizationService,
    $transitions,
    configuration,
    FileUploader,
    StoreService,
    tiersRattachementService,
    themeManagementService
  ) {
    'use strict';

    localizationService.initializeLanguage('fr'); // Use 'localizationServiceProvider.getActiveLocale()' to get language according to user settings
    kendo.culture('fr-FR');

    // kendo > 6.1 uses a verion of jquery that needs jQuery.uniqSort to exists
    if (!kendo.jQuery.uniqueSort) {
      kendo.jQuery.uniqueSort = kendo.jQuery.unique;
    }

    $rootScope.currentUser = jwtSessionService.user();
    //function to open Contact page in a modal
    $rootScope.openContact = function () {
      // In case we try to go to the same content, we refresh the page
      if ($state.current.name === 'app.contact-page') {
        $state.reload();
      } else {
        $state.href('app.contact-page', {
          tenantId: configuration.tenant.id,
        });

        if (
          $rootScope.footer.contact.url &&
          !_.includes(
            $rootScope.footer.contact.url,
            $state.href(
              'app.contact-page',
              {
                tenantId: configuration.tenant.id,
              },

              {
                inherit: false,
              }
            )
          )
        ) {
          return $rootScope.footer.contact.url;
        } else {
          if ($rootScope.currentUser) {
            Promise.all([
              mdmService.fill(['motifscontact']),
              tiersService.getCivilites(),
              viewManagerService.getViewConfiguration('contact', 'form'),
              tiersRattachementService.getRattachementsConfig(),
            ]).spread(function (mdm, civilites, contactConfiguration, rattachementsDocumentConfiguration) {
              const scopeModal = $rootScope.$new();
              scopeModal.viewConfiguration = contactConfiguration;
              scopeModal.mdm = mdm;
              scopeModal.civilites = civilites.raw;
              scopeModal.rattachementsDocumentConfiguration = rattachementsDocumentConfiguration;
              $modal({
                template: 'contact/contact.html',
                scope: scopeModal,
              });
            });
          } else {
            $state.go('app.contact-page');
          }
        }
      }
    };

    // function to open FAQ in modal
    $rootScope.openFaq = function () {
      // In case we try to go to the same content, we refresh the page
      if ($state.current.name === 'app.faq') {
        $state.reload();
      } else {
        const scopeModal = $rootScope.$new();
        _.set(scopeModal, 'viewConfiguration.ns', 'faq');
        $modal({
          template: 'faq/faq-modal.html',
          scope: scopeModal,
        });
      }
    };

    // Access to the state from the view
    $rootScope.$state = $state;

    // Modal management: keep a reference of all opened modals
    let modals = [];

    /**
     *
     */
    function hideModalsAndErrors() {
      // Hide all modals
      if (modals.length) {
        angular.forEach(modals, function ($modal) {
          $modal.$promise.then($modal.hide);
        });
        modals = [];
        $rootScope.blockedNavigation = false;
      }

      $rootScope.$broadcast('hideAlerts');
    }

    $rootScope.$on('modal.show', function (e, $modal) {
      // Manually block navigation when a modal is opened
      $rootScope.blockedNavigation = true;

      // if modal is not already in list
      if (!_.includes(modals, $modal)) {
        modals.push($modal);
      }
    });

    $rootScope.$on('modal.hide', function (e, $modal) {
      $rootScope.blockedNavigation = false;

      const modalIndex = _.indexOf(modals, $modal);
      modals.splice(modalIndex, 1);
    });
    $transitions.onStart({}, function (transition) {
      const to = transition.to();
      const toParams = transition.params();

      if (to.name !== 'app.connected.config.depot.simple') {
        // when we leave depot simple state, we clean pendingPromises array
        StoreService.depot.pendingPromises.clean();
      }

      if (to.name !== 'app.connected.config.depot.demandePaiement') {
        StoreService.demandePaiement.pendingPromises.clean();
      }

      // permet de bloquer la navigation quand on essaye d'outrepasser les modals obligatoires (style CGU)
      if (_.get($rootScope, 'blockedNavigation', false)) {
        transition.abort();
      }

      kendo.destroy($document.body);
      $('.view-root-node').html('');

      const isConnectedStateChild = to.name.indexOf('app.connected.') === 0;
      const user = userSessionService.getUser();
      const hasSelectedTiers = StoreService.currentTiersRef.get() !== null;
      const isTiersSelection = to.name === 'app.connected.tiers-selection';

      if (_.isEmpty(user) && isConnectedStateChild) {
        routeUtilsService.redirectToHome(to.name, toParams);
      } else if (isConnectedStateChild && !hasSelectedTiers && !isTiersSelection) {
        // The user should have a tiers selected when he connects
        // Redirect to the tiers selection screen if he is on a "connected" state
        // and has no tiers selected
        $state.go('app.connected.tiers-selection', {
          tenantId: transition.params().tenantId,
          redirectTo: to.name,
          redirectParams: JSON.stringify(toParams),
        });

        // returning false cancels the current transition
        return false;
      }

      if (modals.length) {
        angular.forEach(modals, function ($modal) {
          $modal.$promise.then($modal.hide);
        });
        modals = [];
      }
      $('.modal').remove();
      $('.modal-backdrop').remove();
      $('body').removeClass('modal-open');

      if (to.redirectTo) {
        $state.go(to.redirectTo, toParams);
      }
    });

    $rootScope.$on('hideModalsAndErrors', hideModalsAndErrors);

    $transitions.onError({}, function (trans) {
      const error = trans.error();
      const to = trans.to();
      const toParams = trans.params();

      if (!error) {
        return;
      }
      if (error.status === 401) {
        jwtSessionStorage.removeJWTInfo();
        $rootScope.currentUser = null;
        routeUtilsService.redirectToHome(to.name, toParams);
      }
      $log.error(error);
    });

    $transitions.onSuccess({}, function (trans) {
      // wait for end of digests
      $timeout(function () {
        autosize($('textarea'));
      }, 0);

      const to = trans.to();
      const fromParams = trans.$from().params;

      $rootScope.previousStateFrom = _.clone(trans.$from());

      // if we are in a different state
      if ($rootScope.previousStateFrom.name !== to.name) {
        // Reinit pageConfigurationKey to flush the value
        delete $rootScope.pageConfigurationKey;
      }

      // In every state change we clear all the old errors and modals
      hideModalsAndErrors();

      if ($state.current.name === 'app.home' && fromParams.redirectTo && $rootScope.currentUser) {
        routeUtilsService.manageRedirection();
      }
      // Redirect to the dashboard when a connected user try to access 'home' state
      else if (
        _.includes(['app.home', 'app.user.account-creation'], $state.current.name) &&
        userSessionService.getUser()
      ) {
        // currentUser might not be initialized from these states
        $rootScope.currentUser = $rootScope.currentUser || userSessionService.getUser();
        $state.go('app.connected.tiers-selection');
      }
    });

    // angular-file-upload : fix upload with the same file
    // https://github.com/nervgh/angular-file-upload/wiki/FAQ#4-no-file-chosen-or-re-add-same-file
    FileUploader.FileSelect.prototype.isEmptyAfterSelection = function () {
      return !this.element.attr('multiple');
    };

    themeManagementService.getMainThemeInfos();
  },
]);
