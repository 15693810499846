'use strict';
indicateursService.$inject = ['configuration', '$httpParamSerializer', 'jwtSessionService', '$http', '$log'];
angular.module('common.services').factory('indicateursService', indicateursService);

/* @ngInject */
/**
 *
 * @param {object} configuration
 * @param {object} $httpParamSerializer
 * @param {object} jwtSessionService
 * @param {object} $http
 * @param {object} $log
 * @returns {object}
 */
function indicateursService(configuration, $httpParamSerializer, jwtSessionService, $http, $log) {
  /**
   * Extract criteres analytiques href collection from demande
   *
   * @param {object} demande
   * @param {object[]} demande.hierarchies
   * @returns {Array} criteresAnalytiques href collection
   */
  const getCriteresAnalytiquesHrefFromDemande = ({ hierarchies = [] }) =>
    _.flatten(hierarchies.map(({ criteresAnalytiques = [] }) => criteresAnalytiques)).map(({ href }) => href);

  /**
   * Get indicateurs for this demande-aide using criteres analytiques
   *
   * @param {object} aide
   * @returns {Promise<object>} indicateurs
   */
  const getIndicateursFromCriteresAnalytiques = (aide) => {
    const criteresAnalytiques = getCriteresAnalytiquesHrefFromDemande(aide);

    // Send request
    return $http
      .post(
        `/referentiel-hierarchies/api/tenants/${configuration.tenant.id}/indicateurs/from-criteres-analytiques`,
        criteresAnalytiques
      )
      .then(function (response) {
        return _.get(response, 'data.data', []);
      })
      .catch(function (err) {
        $log.error(err);
        throw new Error(err);
      });
  };

  /**
   * check if indicateur is pertinent and is not agent only
   * in order to keep the indicators on aide up to date we need to fetch the indicateur in ref-hierarchie
   *
   * @param {object} aide
   * @returns {boolean} return true or false for display page indicateur
   */
  const showIndicateur = (aide) => {
    // get current indicateur updated
    const indicateursCriterePromise = getIndicateursFromCriteresAnalytiques(aide);
    // get indicateur not updated in aide
    const indicateursSaisis = _.get(aide, 'indicateursSaisis');

    // replace href at the root of indicateursSaisis
    const formatIndicateurSaisis = indicateursSaisis.map((indicateur) => {
      indicateur.id = indicateur.definition.href;
      return indicateur;
    });

    return indicateursCriterePromise.then((indicateurCritere) => {
      // get same indicateur in indicateur saisis and indicateur critere
      const intersectionBetweenIndic = _.intersectionBy(formatIndicateurSaisis, indicateurCritere, 'id');
      // get difference between indicateur saisis and indicateur critere
      const differenceIndicateurSaisis = _.differenceBy(formatIndicateurSaisis, indicateurCritere, 'id');
      const differenceIndicateurCritere = _.differenceBy(indicateurCritere, formatIndicateurSaisis, 'id');

      const indicateurMerged = [];

      // push same indicateur saisis and indicateur critere in indicateurMerged
      intersectionBetweenIndic.forEach((indic) => {
        indicateurMerged.push({
          ...indic,
          definition: indicateurCritere.find((critere) => indic.id === critere.id),
        });
      });

      // push indicateur difference in indicateurMerged
      differenceIndicateurSaisis.forEach((indic) => {
        indicateurMerged.push({ ...indic });
      });

      differenceIndicateurCritere.forEach((indic) => {
        indicateurMerged.push({ definition: indic, pertinent: true, href: indic.href });
      });

      // case of display indicateur screen
      // pertinence | pertinent
      // true | true => display screen
      // false | true => display screen
      // false | false => display screen
      // true | false => not display screen

      // filter in indicteur for check if indicateur is pertinent, pertinence and not agentSeulement
      // if this array have length > 0 return true
      return (
        indicateurMerged.filter((indicateur) => {
          // Check if the indicateur setting allows it to be displayed on the client side
          const isNotAgentOnly = !_.get(indicateur, 'definition.agentSeulement');
          // Check if the setting of the indicateur does not allow the relevance (pertinence) management. If it does, then we check if the indicateur is relevant (pertinent).
          const isAPertinentIndicateur = !_.get(indicateur, 'definition.pertinence') || indicateur.pertinent !== false;

          return isNotAgentOnly && isAPertinentIndicateur;
        }).length > 0
      );
    });
  };

  return {
    /**
     * Method that builds url to load the indicateurValues form
     *
     * @param {object} entity
     * @param {boolean} readOnly
     * @param {string} state should be one of those 'previewed' or 'realized'
     * @param {boolean} fetchIndicateurs
     * @param {object} contribution
     * @param {string} origin
     * @returns {string} iframe url
     */
    getIndicateursIframeUrl: (
      entity,
      readOnly = false,
      state = 'previewed',
      fetchIndicateurs = false,
      contribution = null,
      origin = null
    ) => {
      const queryParams = {
        jwtKey: jwtSessionService.getJwtKey(),
        entityId: entity.id,
        state,
        fetchIndicateurs,
        readOnly,
      };

      if (origin) queryParams.origin = origin;
      if (contribution) queryParams.contributionId = contribution.id;

      const serializedQueryParams = $httpParamSerializer(queryParams);
      return `${configuration.referentielHierarchies.frontUrl}${configuration.tenant.id}/indicateurs-saisis/userIndicateursSaisis?${serializedQueryParams}`;
    },

    /**
     * Update data with message sent from indicateurs iframe
     *
     * @param {object} msg
     * @param {Function} accept
     * @param {Function} reject
     * @param {object} aide
     * @returns {Promise<number> | void} Number of indicateurs
     */
    updateIndicateursSaisisOnAide: (msg, accept, reject, aide) => {
      const action = _.get(msg, 'data.action');
      const indicateursSaisis = _.get(msg, 'data.indicateursSaisis');
      // Update aide
      if (action === 'updateIndicateursSaisis' && indicateursSaisis) {
        _.set(aide, 'indicateursSaisis', indicateursSaisis);
        accept();
      } else if (action === 'invalidIndicateursSaisis') {
        reject(new Error('Invalid indicateur form'));
      } else {
        reject(new Error('Unknown error'));
      }
    },

    /**
     * Get indicateurs for this demande-aide using criteres analytiques
     *
     * @param {object} aide
     * @returns {Promise<object>} indicateurs
     */
    getIndicateursFromCriteresAnalytiques,

    /**
     * check if indicateur is pertinent and is not agent only
     *
     * @param {object} aide
     * @returns {boolean} return true or false for display page indicateur
     */
    showIndicateur,

    /**
     * Get the number of indicateurs for this demande-aide using criteres analytiques
     *
     * @param {object} aide
     * @returns {Promise<number>} number of indicateurs
     */
    getIndicateursCount: (aide) => {
      return getIndicateursFromCriteresAnalytiques(aide).then((result) => result.length);
    },

    getCriteresAnalytiquesHrefFromDemande,

    /**
     * The indicateurs list should NOT be fetched when (by priority in descending order):
     * - if we are in the case of a contribution
     * - user has already visited "indicateurs saisis" page (aide)
     * - this is NOT a contribution and indicateursSaisis is NOT empty
     * In any other scenario, indicateurs list should be fetched.
     *
     * @param {object} history history object from aide or contribution
     * @param {boolean} isContribution `true` if user is editing a contribution, else `false`
     * @param {Array} indicateursSaisis list of indicateurs saisis
     * @returns {boolean} `true` if indicateurs should be fetched, else `false`
     */
    shouldFetchIndicateurs(history, isContribution, indicateursSaisis = []) {
      // if we are in the case of a contribution fetch indicateurs
      if (isContribution) {
        return true;
      }

      const stepsVisited = _.get(history, 'begin.metadata.stepsVisited', []);
      // If steps already visited, do NOT fetch
      if (_.includes(stepsVisited, 'indicateurs')) {
        return false;
      }

      // If indicateursSaisis is empty, fetch!
      return !indicateursSaisis.length;
    },
  };
}
